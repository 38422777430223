import React from 'react';
import { Alert, Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import * as Updates from 'expo-updates';
import { createGlobalStyle } from 'styled-components';

import {
  Nunito_200ExtraLight,
  Nunito_300Light,
  Nunito_400Regular,
  Nunito_600SemiBold,
  Nunito_700Bold,
  Nunito_800ExtraBold,
  Nunito_900Black,
  useFonts,
} from '@expo-google-fonts/nunito';

import useStore from '$store';
import Theme from '$theme';

import Router from './Router';
import Session from './Session';
import Splash from './Splash';

const Root: React.FC = () => {
  const store = useStore();

  const [fontsLoaded] = useFonts({
    Nunito_200ExtraLight,
    Nunito_300Light,
    Nunito_400Regular,
    Nunito_600SemiBold,
    Nunito_700Bold,
    Nunito_800ExtraBold,
    Nunito_900Black,
  });

  React.useEffect(() => {
    if (__DEV__ || Platform.OS === 'web') return;

    Updates.checkForUpdateAsync().then(async ({ isAvailable }) => {
      if (!isAvailable) return;

      const { isNew } = await Updates.fetchUpdateAsync();
      if (!isNew) return;

      store.setHasUpdate(true);

      Alert.alert('Atualização disponível', 'Deseja reiniciar o aplicativo agora para aplicar a nova versão?', [
        { text: 'Não' },
        { text: 'Sim', onPress: () => Updates.reloadAsync() },
      ]);
    });
  }, []);

  if (!fontsLoaded) {
    return (
      <>
        <GlobalStyle />
        <Splash />
      </>
    );
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <GlobalStyle />
      <Theme>
        <SafeAreaProvider>
          <StatusBar translucent style="light" />

          <Session>
            <Router />
          </Session>
        </SafeAreaProvider>
      </Theme>
    </GestureHandlerRootView>
  );
};

const GlobalStyle =
  Platform.OS !== 'web'
    ? () => null
    : createGlobalStyle`
  body {
    background-color: #202020;
  }

  ::-webkit-scrollbar {
    width: 6px!important;
    height: 6px!important;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .16);
  }
`;

export default Root;
