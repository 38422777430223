import React from 'react';
import { Platform } from 'react-native';

import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Text from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { getNavigation, navigate } from '$navigation';

import Badge from './Badge';

export interface Tab {
  caption: string;
  icon: string;
  screen: string;
  badge?: number;
}

interface Props {
  tabs: Tab[];
}

const Navigation: React.FC<Props> = ({ tabs }) => {
  if (Platform.OS !== 'web') return <Root />;

  const nav = getNavigation();
  const [currIdx, setCurrIdx] = React.useState(0);

  React.useEffect(() => {
    if (!nav) return;

    function handler(state: any) {
      if (!nav) return;
      const idx = tabs.findIndex((tab) => tab.screen === nav.getCurrentRoute()?.name);
      if (idx > -1) setCurrIdx(idx);
    }

    nav.addListener('state', handler);
    return () => nav.removeListener('state', handler);
  }, [nav]);

  return (
    <Root>
      {tabs.map((tab, idx) => {
        const active = idx === currIdx;

        return (
          <Touchable key={tab.screen} onPress={() => navigate(tab.screen)}>
            <Tab active={active}>
              <AntDesign size={24} name={tab.icon as any} color={active ? '#ddd' : '#999'} />

              <Text p2 faded={!active} bold spacedTop={0.4}>
                {tab.caption}
              </Text>

              <Badge badge={tab.badge} style={{ backgroundColor: '#c22', top: 2, left: 44 }} />
            </Tab>
          </Touchable>
        );
      })}
    </Root>
  );
};

const Root = styled.View`
  flex: 1;
  flex-direction: row;
`;

const Tab = styled.View<{ active: boolean }>`
  width: 80px;
  padding: 8px 6px 6px;
  align-items: center;
  background-color: ${({ active }) => (active ? '#00000020' : 'transparent')};
`;

export default Navigation;
