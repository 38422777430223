import React from 'react';
import { Animated } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { LinearGradient, Path, Stop } from 'react-native-svg';

import * as shape from 'd3-shape';
import { LinearGradient as LinearGradientView } from 'expo-linear-gradient';
import styled, { useTheme } from 'styled-components/native';

interface Props {
  activeIdx: number;
  width: number;
  tabHoleWidth: number;
  tabWidth: number;
  tabHeight: number;
  curveWidth: number;
}

const MovableBarNotch: React.FC<Props> = ({
  activeIdx,
  width,
  tabHoleWidth,
  tabWidth,
  tabHeight,
  curveWidth,
}: Props) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const d = React.useMemo(() => {
    // diferença do tamanho da barra e do tamanho do buraco
    const m = (tabWidth - tabHoleWidth) / 2;

    const left = shape
      .line()
      .x((x) => x[0])
      .y((x) => x[1])([
      [0, 0],
      [width, 0],
    ]);

    const tab = shape
      .line()
      .x((x) => x[0])
      .y((x) => x[1])
      .curve(shape.curveBasis)([
      [width, 0],
      [width + curveWidth + m, 0],

      [width + curveWidth + m + tabHoleWidth * 0.2, tabHeight * 0.8],
      [width + curveWidth + m + tabHoleWidth * 0.5, tabHeight * 0.9],
      [width + curveWidth + m + tabHoleWidth * 0.8, tabHeight * 0.8],

      [width + curveWidth + m + tabHoleWidth, 0],
      [width + curveWidth * 2 + tabWidth, 0],
    ]);

    const right = shape
      .line()
      .x((x) => x[0])
      .y((x) => x[1])([
      [width + tabWidth, 0],
      [width * 2 + curveWidth * 2 + tabWidth, 0],
      [width * 2 + curveWidth * 2 + tabWidth, tabHeight + insets.bottom],
      [0, tabHeight + insets.bottom],
      [0, 0],
    ]);

    return `${left} ${tab} ${right}`;
  }, [width, insets.bottom]);

  const anim = React.useRef(new Animated.Value(-width - curveWidth)).current;

  React.useEffect(() => {
    Animated.timing(anim, {
      toValue: -width - curveWidth + tabWidth * activeIdx,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [width, curveWidth, tabWidth, activeIdx]);

  return (
    <>
      <Shadow
        colors={['#00000000', '#00000099', '#000000FF']}
        style={{ height: tabHeight + 8, bottom: insets.bottom }}
      />

      <Background
        width={width * 2 + curveWidth * 2 + tabWidth}
        height={tabHeight + insets.bottom}
        style={{
          transform: [{ translateX: anim }],
        }}
      >
        <LinearGradient id="fill" x1="0%" y1="0%" x2="0%" y2="100%">
          <Stop offset="0%" stopColor={theme.primary.darken(0.3).desaturate(0.3).hex()} />
          <Stop offset="100%" stopColor={theme.primary.darken(0.5).desaturate(0.5).hex()} />
        </LinearGradient>

        <Path d={d} fill="url(#fill)" />
      </Background>
    </>
  );
};

const Background = Animated.createAnimatedComponent(Svg);

const Shadow = styled(LinearGradientView)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default MovableBarNotch;
