import React from 'react';
import { Platform } from 'react-native';

import { observer } from 'mobx-react-lite';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import BottomBar from '$components/Layout/BottomBar';
import TopBar from '$components/Layout/TopBar';

import Accounts from '$screens/Accounts';
import Approval from '$screens/Approval';
import Feedback from '$screens/Feedback';
import Home from '$screens/Home';
import ImagesViewer from '$screens/ImagesViewer';
import Lead from '$screens/Lead';
import Leads from '$screens/Leads';
import LeadsFilter from '$screens/LeadsFilter';
import Profile from '$screens/Profile';
import Task from '$screens/Task';
import Tasks from '$screens/Tasks';
import VideosViewer from '$screens/VideosViewer';

import { useBadgeCountersLazyQuery } from '$graphql';
import useStore from '$store';

import { navigationRef } from './Navigation';
import { theme } from './Theme';

const RootStack = createStackNavigator();
const MainStack = createStackNavigator();

const linking = {
  prefixes: ['https://app.idaction.com.br', 'idaction://'],
  config: {
    screens: {
      Home: '',
      Main: 'home',
      Login: 'login',
      Tasks: 'tasks',
      Feedback: 'feedback',
    },
  },
};

function RootStackNavigator() {
  return (
    <RootStack.Navigator screenOptions={{ headerShown: false, headerMode: 'float' }}>
      <RootStack.Screen name="Main" component={MainStackNavigator} />
      <RootStack.Screen name="Accounts" component={Accounts} />
      <RootStack.Screen name="Task" component={Task} />
      <RootStack.Screen name="ImagesViewer" component={ImagesViewer} />
      <RootStack.Screen name="VideosViewer" component={VideosViewer} />
      <RootStack.Screen name="Lead" component={Lead} />
      <RootStack.Screen name="Feedback" component={Feedback} />
    </RootStack.Navigator>
  );
}

function MainStackNavigator() {
  return (
    <MainStack.Navigator screenOptions={{ headerShown: false, gestureEnabled: false }}>
      <MainStack.Screen name="Home" component={Home} />
      <MainStack.Screen name="Approval" component={Approval} />
      <MainStack.Screen name="Tasks" component={Tasks} />
      <MainStack.Screen name="Leads" component={Leads} />
      <MainStack.Screen name="LeadsFilter" component={LeadsFilter} />
      <MainStack.Screen name="Profile" component={Profile} />
    </MainStack.Navigator>
  );
}

const Router: React.FC = () => {
  const store = useStore();

  const [fetchBadgeCounters, { data, loading }] = useBadgeCountersLazyQuery();

  const [tabs, setTabs] = React.useState<any[]>([
    { screen: 'Home', icon: 'appstore-o', caption: 'Home' },
    { screen: 'Approval', icon: 'checkcircleo', caption: 'Aprovação' },
    { screen: 'Tasks', icon: 'calendar', caption: 'Atividades' },
    { screen: 'Leads', icon: 'contacts', caption: 'Leads' },
    { screen: 'Profile', icon: 'user', caption: 'Perfil' },
  ]);

  React.useEffect(() => {
    if (!store.badgeIsDirty || !store.selectedAccountId) return;
    fetchBadgeCounters({ variables: { account: store.selectedAccountId } });
    store.setBadgeDirty(false);
  }, [store.badgeIsDirty]);

  React.useEffect(() => {
    if (!data || loading) return;

    const approval = data.account.stats.tasks.steps.find(({ step }) => step === 'approval');
    const leads = data.account.stats.leads.classifications.find(({ classification }) => classification === 'none');

    setTabs((tabs) => {
      const newTabs = [...tabs];
      newTabs[1].badge = approval?.count ?? 0;
      newTabs[3].badge = leads?.count ?? 0;
      newTabs[4].badge = store.hasUpdate ? 1 : 0;
      return newTabs;
    });
  }, [data, store.hasUpdate]);

  return (
    <>
      <TopBar tabs={tabs} />

      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        theme={{
          dark: true,
          colors: {
            primary: theme.primary.hex(),
            background: theme.primary.desaturate(0.5).darken(0.5).hex(),
            border: '#000',
            card: '#000',
            notification: '#000',
            text: '#000',
          },
        }}
      >
        <RootStackNavigator />
      </NavigationContainer>

      {Platform.OS !== 'web' && <BottomBar tabs={tabs} />}
    </>
  );
};

export default observer(Router);
