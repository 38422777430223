import React from 'react';
import { Text as RNText, TextProps } from 'react-native';

import styled from 'styled-components/native';

import { getBaseStyles, StyleProps } from '$logic/style';

export interface Props extends StyleProps, TextProps {
  black?: boolean;
  extrabold?: boolean;
  bold?: boolean;
  semibold?: boolean;
  regular?: boolean;
  light?: boolean;

  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  p1?: boolean;
  p2?: boolean;
  p3?: boolean;

  centered?: boolean;
  faded?: boolean;
  disabled?: boolean;
}

const Text = React.forwardRef<RNText, React.ComponentPropsWithoutRef<React.ElementType<Props>>>((props, ref) => {
  return <El {...props} ref={ref} />;
});

export default Text;

const El = styled.Text<Props>`
  ${getBaseStyles};
  color: rgba(255, 255, 255, ${getTextOpacity});
  font-family: ${getTextFont};
  font-size: ${getTextSize};
  text-align: ${getTextAlign};
`;

function getTextFont({ black, extrabold, bold, semibold, regular, light, h1, h2, h3, h4, p1, p2, p3 }: Props) {
  if (black) return 'Nunito_900Black';
  if (extrabold) return 'Nunito_800ExtraBold';
  if (bold) return 'Nunito_700Bold';
  if (semibold) return 'Nunito_600SemiBold';
  if (regular) return 'Nunito_400Regular';
  if (light) return 'Nunito_300Light';

  if (h1) return 'Nunito_700Bold';
  if (h2) return 'Nunito_600SemiBold';
  if (h3) return 'Nunito_600SemiBold';
  if (h4) return 'Nunito_600SemiBold';

  if (p1) return 'Nunito_400Regular';
  if (p2) return 'Nunito_400Regular';
  if (p3) return 'Nunito_300Light';

  return 'Nunito_400Regular';
}

function getTextSize({ h1, h2, h3, h4, p1, p2, p3 }: Props) {
  if (h1) return '20px';
  if (h2) return '18px';
  if (h3) return '16px';
  if (h4) return '14px';
  if (p1) return '13px';
  if (p2) return '11px';
  if (p3) return '11px';
  return '14px';
}

function getTextOpacity({ faded, disabled }: Props) {
  if (disabled) return '0.37';
  if (faded) return '0.60';
  return '0.87';
}

function getTextAlign({ centered }: Props) {
  return centered ? 'center' : 'left';
}
