import React from 'react';
import { View } from 'react-native';
import { FlashList } from '@shopify/flash-list';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useAccountsListQuery } from '$graphql';
import { goBack } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

const Accounts: React.FC = () => {
  const store = useStore();

  const { data, loading, error } = useAccountsListQuery();

  const selectedAccount = (data?.me.accounts.data ?? []).find((acc) => acc.id === store.selectedAccountId);

  function handleSelectAccount(id: string) {
    store.setSelectedAccountId(id);
    goBack();
  }

  return (
    <Screen style={{ overflow: 'scroll' }}>
      {!data && loading && <Loader size="large" />}
      {error && <Alert error message="Ocorreu um erro ao carregar as contas" description={error.message} />}

      <View style={{ ...getMaxConstraints(), flex: 1 }}>
        <FlashList
          estimatedItemSize={53}
          data={data?.me.accounts.data}
          keyExtractor={(acc) => acc.id}
          contentContainerStyle={{ paddingBottom: 72 }}
          scrollIndicatorInsets={{ bottom: 50 }}
          renderItem={({ item: acc }) => {
            const selected = acc.id === selectedAccount?.id;

            const approvalCount = acc.stats.tasks.steps.find((step) => step.step === 'approval')?.count;

            return (
              <Touchable onPress={() => handleSelectAccount(acc.id)}>
                <AccountItem
                  selected={selected}
                  style={{
                    borderTopColor: '#00000040',
                    borderTopWidth: 0.25,
                    borderBottomColor: '#00000040',
                    borderBottomWidth: 0.25,
                  }}
                >
                  {!!acc.logoUrl && (
                    <AccountLogo style={{ opacity: selected ? 1 : 0.8 }} source={{ uri: acc.logoUrl }} />
                  )}

                  <Text h4 faded={!selected} spacedY spacedLeft style={{ flex: 1 }} numberOfLines={1}>
                    {acc.name}
                  </Text>

                  {!!approvalCount && (
                    <Badge>
                      <Text black centered style={{ fontSize: 11 }}>
                        {approvalCount}
                      </Text>
                    </Badge>
                  )}
                </AccountItem>
              </Touchable>
            );
          }}
        />
      </View>
    </Screen>
  );
};

const AccountLogo = styled.Image`
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

const AccountItem = styled.View<{ selected: boolean }>`
  padding: 8px 12px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary.desaturate(0.3).darken(0.3).hex() : 'transparent'};
`;

const Badge = styled.View`
  min-width: 19px;
  min-height: 19px;
  padding: 3px 5px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #c22;
`;

export default observer(Accounts);
