import React from 'react';
import { Alert as RNAlert, findNodeHandle, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { parsePhoneNumber } from 'awesome-phonenumber';
import * as Linking from 'expo-linking';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import styled from 'styled-components/native';

import { Feather, FontAwesome } from '@expo/vector-icons';

import Input from '$components/DataEntry/Input';
import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Button from '$components/General/Button';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import {
  LeadClassification,
  useDeleteLeadMutation,
  useLeadQuery,
  useUpdateLeadClassificationMutation,
  useUpdateLeadNotesMutation,
} from '$graphql';
import { goBack, navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

interface Props {
  route?: any;
}

const Lead: React.FC<Props> = ({ route }) => {
  const store = useStore();

  const scrollRef = React.useRef<any>();
  const inputRef = React.useRef<any>();
  const prevSelectedAccountId = React.useRef(store.selectedAccountId);

  const [notes, setNotes] = React.useState('');

  const { data, loading, error } = useLeadQuery({
    variables: { leadId: route.params.id, accountId: store.selectedAccountId ?? '' },
    fetchPolicy: 'network-only',
  });

  const [deleteLead, { loading: deletingLead }] = useDeleteLeadMutation();

  React.useEffect(() => {
    if (!store.selectedAccountId || store.selectedAccountId === prevSelectedAccountId.current) return;
    navigate('Leads');
  }, [store.selectedAccountId]);

  React.useEffect(() => {
    if (!data) return;
    setNotes(data.lead.notes ?? '');
  }, [data?.lead.notes]);

  const [updateClassification, { loading: updatingClassification }] = useUpdateLeadClassificationMutation();
  const [updateNotes, { loading: updatingNotes }] = useUpdateLeadNotesMutation();

  async function handleClassify(classification: LeadClassification) {
    const { errors } = await updateClassification({
      variables: {
        id: route.params.id,
        classification: data?.lead.classification === classification ? LeadClassification.None : classification,
      },
    });

    if (errors) {
      return RNAlert.alert(
        'Ocorreu um erro',
        'Não foi possível salvar as informações do lead neste momento. Se o problema persistir, feche o aplicativo e tente novamente.',
      );
    }

    store.setBadgeDirty(true);
  }

  async function handleBlur() {
    const { errors } = await updateNotes({ variables: { id: route.params.id, notes } });

    if (errors) {
      return RNAlert.alert(
        'Ocorreu um erro',
        'Não foi possível salvar as informações do lead neste momento. Se o problema persistir, feche o aplicativo e tente novamente.',
      );
    }
  }

  function handleFocus(e: any) {
    if (!scrollRef.current || !scrollRef.current.scrollToFocusedInput) return;
    scrollRef.current.scrollToFocusedInput(findNodeHandle(e.target));
  }

  async function handleRemoveLead() {
    if (!data) return;

    if (Platform.OS === 'web') {
      let ret = window.confirm('Você tem certeza que deseja remover este lead');

      if (ret) {
        await deleteLead({ variables: { id: data.lead.id } });
        store.setMustRefetchleads(true);
        goBack();
      }
    } else {
      RNAlert.alert('Remover lead', 'Você tem certeza que deseja remover este lead?', [
        {
          text: 'Sim',
          style: 'destructive',
          onPress: async () => {
            await deleteLead({ variables: { id: data.lead.id } });
            store.setMustRefetchleads(true);
            goBack();
          },
        },
        {
          text: 'Cancelar',
          style: 'cancel',
        },
      ]);
    }
  }

  let leadType = 'normal';
  if (data?.lead.source === 'facebook') leadType = 'Facebook';
  if (data?.lead.source === 'whatsapp') leadType = 'WhatsApp';
  if (data?.lead.source === 'website') leadType = 'Website';
  if (data?.lead.source === 'linkedin') leadType = 'Linkedin';

  const sourceIcons = {
    none: <FontAwesome name="user-circle-o" size={20} color="#fff" />,
    facebook: <FontAwesome name="facebook-official" size={20} color="#fff" />,
    whatsapp: <FontAwesome name="whatsapp" size={20} color="#fff" />,
    website: <FontAwesome name="globe" size={20} color="#fff" />,
    linkedin: <FontAwesome name="linkedin-square" size={20} color="#fff" />,
  };

  return (
    <Screen>
      <KeyboardAwareScrollView
        enableOnAndroid
        contentContainerStyle={{ padding: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        extraHeight={200}
        ref={scrollRef}
      >
        {loading && <Loader size="large" />}
        {error && <Alert error message="Ocorreu um erro ao carregar a atividade" description={error.message} />}

        {data && (
          <>
            <Text h1 bold>
              {sourceIcons[data.lead.source]}&nbsp;
              {data.lead.name}
            </Text>
            <Text faded>
              Lead via {leadType} cadastrado em&nbsp;
              {moment.utc(data.lead.subscribed_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY [às] HH:mm')}
            </Text>

            {data.lead.emails.length > 0 && (
              <>
                <Text h4 spacedTop={4} spacedBottom={0.5}>
                  E-mail
                </Text>
                {data.lead.emails.map((email, i) => (
                  <FieldActions key={i}>
                    <Touchable onPress={() => Linking.openURL(`mailto:${email}`)}>
                      <EmailButton>
                        <FontAwesome name="envelope-o" size={18} color="#0f81a3" />
                      </EmailButton>
                    </Touchable>
                    <Text h3>{email}</Text>
                  </FieldActions>
                ))}
              </>
            )}

            {data.lead.phones.length > 0 && (
              <>
                <Text h4 spacedTop={2} spacedBottom={0.5}>
                  Telefone
                </Text>
                {data.lead.phones.map((phone, i) => {
                  let e164 = phone;
                  let rfc3966 = phone;
                  let national = phone;

                  const phoneObj = parsePhoneNumber(phone, { regionCode: 'BR' });

                  if (phoneObj.valid) {
                    e164 = phoneObj.number.e164;
                    rfc3966 = phoneObj.number.rfc3966;
                    national = phoneObj.number.national;
                  }

                  return (
                    <FieldActions key={i}>
                      <Touchable onPress={() => Linking.openURL(`whatsapp://send?phone=${e164}`)}>
                        <WhatsAppButton>
                          <FontAwesome name="whatsapp" size={18} color="#09b27f" />
                        </WhatsAppButton>
                      </Touchable>
                      <Touchable onPress={() => Linking.openURL(rfc3966)}>
                        <PhoneButton>
                          <Feather name="phone" size={18} color="#0f81a3" />
                        </PhoneButton>
                      </Touchable>
                      <Text h3>{national}</Text>
                    </FieldActions>
                  );
                })}
              </>
            )}

            <>
              <Text h4 spacedTop={1.5} spacedBottom={0.5}>
                Opções
              </Text>
              <FieldActions>
                <Touchable onPress={handleRemoveLead} disabled={deletingLead}>
                  <TrashButton>
                    <FontAwesome name="trash-o" size={18} color="#fff" />
                    <Text spacedLeft spacedRight={deletingLead ? 1 : 0}>
                      Remover lead
                    </Text>
                    {deletingLead && <Loader color="#fff" margin={0} />}
                  </TrashButton>
                </Touchable>
              </FieldActions>
            </>

            {(data.account.mailerlite_fields || []).map((field) => {
              const found = (data.lead.fields || []).find((leadField) => leadField.key === field.key);
              if (!found) return null;

              return (
                <>
                  <Text h4 spacedTop={2} spacedBottom={0.5}>
                    {field.name}
                  </Text>
                  <CustomFieldSpace>
                    <Text faded>{found.value}</Text>
                  </CustomFieldSpace>
                </>
              );
            })}

            <Text h4 spacedTop={2} spacedBottom={0.5}>
              Observações
            </Text>
            <Input
              multiline
              placeholder="Escreva aqui informações sobre este lead"
              ref={inputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={notes}
              onChangeText={(text: string) => setNotes(text)}
            />

            <Text h4 spacedTop={2} spacedBottom={0.5}>
              Classificação do lead
            </Text>

            <QualificationsActions>
              <Touchable onPress={() => handleClassify(LeadClassification.NoContact)}>
                <QualificationButton
                  style={{ backgroundColor: data.lead.classification === 'no_contact' ? '#d64848' : '#00000040' }}
                >
                  <Feather
                    name="user-x"
                    color={data.lead.classification === 'no_contact' ? '#FFF' : '#d64848'}
                    size={24}
                  />
                  <Text p2 spacedTop>
                    Sem contato
                  </Text>
                </QualificationButton>
              </Touchable>

              <Touchable onPress={() => handleClassify(LeadClassification.NoProfile)}>
                <QualificationButton
                  style={{ backgroundColor: data.lead.classification === 'no_profile' ? '#ff6f1b' : '#00000040' }}
                >
                  <Feather
                    name="user"
                    color={data.lead.classification === 'no_profile' ? '#FFF' : '#ff6f1b'}
                    size={24}
                  />
                  <Text p2 spacedTop>
                    Sem perfil
                  </Text>
                </QualificationButton>
              </Touchable>

              <Touchable onPress={() => handleClassify(LeadClassification.HasProfile)}>
                <QualificationButton
                  style={{ backgroundColor: data.lead.classification === 'has_profile' ? '#0f81a3' : '#00000040' }}
                >
                  <Feather
                    name="user-check"
                    color={data.lead.classification === 'has_profile' ? '#FFF' : '#0f81a3'}
                    size={24}
                  />
                  <Text p2 spacedTop>
                    Com perfil
                  </Text>
                </QualificationButton>
              </Touchable>

              <Touchable onPress={() => handleClassify(LeadClassification.Closed)}>
                <QualificationButton
                  style={{ backgroundColor: data.lead.classification === 'closed' ? '#09b27f' : '#00000040' }}
                >
                  <FontAwesome
                    name="money"
                    color={data.lead.classification === 'closed' ? '#FFF' : '#09b27f'}
                    size={24}
                  />
                  <Text p2 spacedTop>
                    Fechado
                  </Text>
                </QualificationButton>
              </Touchable>
            </QualificationsActions>
          </>
        )}

        {!loading && (
          <Button
            textProps={{ h3: true, centered: true }}
            spacedTop={4}
            padded
            onPress={() => goBack()}
            disabled={updatingNotes || updatingClassification}
          >
            Voltar
          </Button>
        )}
      </KeyboardAwareScrollView>
    </Screen>
  );
};

const FieldActions = styled.View`
  padding: 2px 0;
  flex-direction: row;
  align-items: center;
`;

const EmailButton = styled.View`
  padding: 8px 10px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: #00000040;
`;

const TrashButton = styled.View`
  flex-direction: row;
  padding: 8px 10px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: #cc222280;
`;

const WhatsAppButton = styled.View`
  padding: 8px 10px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #00000040;
`;

const PhoneButton = styled.View`
  padding: 8px 10px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: #00000040;
`;

const QualificationsActions = styled.View`
  flex-direction: row;
  margin: 0 -2px;
`;

const QualificationButton = styled.View`
  flex: 1;
  padding: 8px 2px;
  margin: 0 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const CustomFieldSpace = styled.View`
  padding: 8px 10px;
  background-color: #00000040;
  border-radius: 4px;
`;

export default observer(Lead);
