import React, { ReactNode, useEffect, useState } from 'react';
import { View, Modal, Platform, Dimensions } from 'react-native';
import { AntDesign as Icon } from '@expo/vector-icons';

import {
  CloseModal,
  ContainerModal,
  SelectTouchableInput,
  TextSelect,
  Wrapper,
  Option,
  OptionText,
  CloseContainer,
} from './styles';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components';

interface SelectProps {
  title?: string;
  options: [{ label: string; value: string }];
  onChange?: (option: [label: string, value: string]) => void;
  chosen?: string;
  children?: ReactNode;
  disabled?: boolean;
}

function Select({ title, chosen, disabled, options, onChange }: SelectProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const height = Dimensions.get('screen').height;

  useEffect(() => {
    setModalVisible(false);
  }, [chosen]);

  const handleChange = (option: any) => {
    if (onChange) onChange(option);
  };

  if (Platform.OS === 'web')
    return (
      <WebSelect tag="select" onChange={({ target: { value: val } }: any) => handleChange({ value: val })}>
        {options.map(({ value, label }) => {
          return React.createElement('option', { value, key: value }, label);
        })}
      </WebSelect>
    );

  return (
    <>
      <View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <ContainerModal
            onPress={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <CloseContainer>
              <CloseModal
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <Icon name="close" color="#d64848" size={30} />
              </CloseModal>
            </CloseContainer>
            <Wrapper>
              <ScrollView style={{ marginBottom: Platform.OS === 'ios' ? 30 : 0 }}>
                {options.map((option) => (
                  <Select.Option key={option?.value} onPress={() => handleChange(option)}>
                    <Select.OptionText>{option?.label}</Select.OptionText>
                  </Select.Option>
                ))}
              </ScrollView>
            </Wrapper>
          </ContainerModal>
        </Modal>
      </View>

      <SelectTouchableInput
        isSelected={chosen ? true : false}
        onPress={() => {
          !disabled ? setModalVisible(!modalVisible) : null;
        }}
      >
        <TextSelect isExistChosen={chosen ? true : false} numberOfLines={1} ellipsizeMode="tail">
          {chosen || title}
        </TextSelect>
        <Icon style={{ right: height > 700 ? 0 : 15 }} name="caretdown" color="rgba(255, 255, 255, 0.87)" size={15} />
      </SelectTouchableInput>
    </>
  );
}

Select.Option = Option;
Select.OptionText = OptionText;

const WebSelect = styled(({ tag, children, ...props }) => React.createElement(tag, props, children))`
  padding: 8px 12px;
  background-color: #00000060;
  height: 40px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  border-radius: 2px;
  outline-style: none;
  border: 0px solid black;
  box-sizing: border-box;
  font-family: 'Nunito_400Regular';
  width: 100%;
`;

export default Select;
