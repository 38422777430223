import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Updates from 'expo-updates';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';

import { FontAwesome } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Button from '$components/General/Button';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import A from '$components/Navigation/A';

import { useDeleteDeviceInfoMutation } from '$graphql';
import { eraseData } from '$storage';
import useStore from '$store';

import pkg from '../../../package.json';
import { Platform, View } from 'react-native';
import { getMaxConstraints } from '$logic/style';

function Profile() {
  const store = useStore();

  const { t } = useTranslation('login');

  const [deleteDeviceInfo, { loading }] = useDeleteDeviceInfoMutation();

  async function handleLogout() {
    if (store.deviceId) await deleteDeviceInfo({ variables: { deviceId: store.deviceId } });
    eraseData('token');
    store.setUser(null);
  }

  return (
    <Screen>
      <ProfileContent>
        <AvatarRoot>
          {!!store.user.avatarUrl && <Avatar source={{ uri: store.user.avatarUrl }} />}
          {!store.user.avatarUrl && <FontAwesome name="user-circle-o" size={96} color="#FFF" />}
        </AvatarRoot>

        <Text h1 centered bold spacedTop={2}>
          {store.user.name}
        </Text>

        <Text centered>{store.user.email}</Text>

        <Alert
          spaced
          spacedTop={2}
          message="Estamos aprimorando a sua experiência em nosso App e em breve mais opções de customização do seu perfil aparecerão aqui."
        />

        {store.hasUpdate && (
          <Alert
            spacedX
            warning
            message="Atualização disponível"
            description="Clique aqui para reiniciar o aplicativo e aplicar a nova versão"
            onPress={() => Updates.reloadAsync()}
          />
        )}

        <Button spaced padded textProps={{ h3: true }} onPress={handleLogout} disabled={loading}>
          Encerrar sessão
        </Button>
      </ProfileContent>

      <Footer>
        <FooterLinks>
          <A faded spacedX to="https://idaction.com.br/tos">
            {t('tos')}
          </A>
          <A faded spacedX to="https://idaction.com.br/privacy">
            {t('privacy')}
          </A>
        </FooterLinks>

        <Text faded centered h4>
          ID Action - O seu marketing no piloto automático
        </Text>

        <Text disabled centered p1>
          versão {pkg.version}
        </Text>
      </Footer>
    </Screen>
  );
}

const ProfileContent: React.FC = (props) => {
  if (Platform.OS === 'web') return <View style={{ ...getMaxConstraints() }}>{props.children}</View>;
  return <>{props.children}</>;
};

const AvatarRoot = styled.View`
  padding: 48px 0 0;
  align-items: center;
`;

const Avatar = styled.Image`
  width: 96px;
  height: 96px;
  border-radius: 48px;
  background-color: #00000080;
`;

const Footer = styled.View`
  flex: 1;
  padding-bottom: 72px;
  justify-content: flex-end;
`;

const FooterLinks = styled.View`
  justify-content: center;
  flex-direction: row;
  padding-bottom: 20px;
`;

export default observer(Profile);
