import React from 'react';

import styled, { DefaultTheme, ThemeProps } from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Text from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { getBaseStyles, StyleProps } from '$logic/style';

interface Props extends StyleProps {
  message: React.ReactNode;
  description?: React.ReactNode;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  info?: boolean;
  icon?: React.ReactElement;
  onPress?: () => void;
}

const Alert: React.FC<Props> = (props) => {
  const Icon = React.useMemo(() => {
    if (props.icon) {
      return React.cloneElement(props.icon, { size: 16, color: 'rgba(255,255,255,0.87)' });
    }

    let icon = <AntDesign name="infocirlceo" size={16} color="rgba(255,255,255,0.87)" />;

    if (props.success) {
      icon = <AntDesign name="checkcircleo" size={16} color="rgba(255,255,255,0.87)" />;
    }

    if (props.warning) {
      icon = <AntDesign name="exclamationcircleo" size={16} color="rgba(255,255,255,0.87)" />;
    }

    if (props.error) {
      icon = <AntDesign name="closecircleo" size={16} color="rgba(255,255,255,0.87)" />;
    }

    return icon;
  }, [props.icon, props.success, props.warning, props.error]);

  if (props.description) {
    return (
      <Touchable onPress={props.onPress}>
        <Container {...props}>
          <IconContainer>{Icon}</IconContainer>
          <ContentContainer>
            <MessageWithDescription h4>{props.message}</MessageWithDescription>
            <Description faded>{props.description}</Description>
          </ContentContainer>
        </Container>
      </Touchable>
    );
  }

  return (
    <Touchable onPress={props.onPress}>
      <Container {...props}>
        <IconContainer>{Icon}</IconContainer>
        <ContentContainer>
          <MessageOnly>{props.message}</MessageOnly>
        </ContentContainer>
      </Container>
    </Touchable>
  );
};

const Container = styled.View`
  ${getBaseStyles}
  ${getTypeStyles}

  padding: 9px 12px 8px 12px;
  border-radius: 2px;
  flex-direction: row;
`;

const IconContainer = styled.View`
  padding: 1px 8px 0 0px;
`;

const ContentContainer = styled.View`
  flex: 1;
`;

const MessageOnly = Text;
const MessageWithDescription = Text;
const Description = Text;

function getTypeStyles(props: Props & ThemeProps<DefaultTheme>) {
  let color = props.theme.primary;

  if (props.success) color = props.theme.success;
  if (props.warning) color = props.theme.warning;
  if (props.error) color = props.theme.error;
  if (props.info) color = props.theme.info;

  return `
    background-color: ${color};
  `;
}

export default Alert;
