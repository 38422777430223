import React from 'react';
import { Animated } from 'react-native';

import styled from 'styled-components/native';

interface Props {
  idx: number;
  activeIdx: number;
  tabWidth: number;
}

const ActiveIcon: React.FC<Props> = ({ idx, activeIdx, tabWidth, children }) => {
  const anim = React.useRef(new Animated.Value(idx === activeIdx ? 1 : 0)).current;

  React.useEffect(() => {
    // Exibe ou oculta o botão dependendo do activeIdx
    if (activeIdx === idx) {
      Animated.timing(anim, {
        toValue: 1,
        duration: 300,
        delay: 100,
        useNativeDriver: true,
        isInteraction: true,
      }).start();
    } else {
      Animated.timing(anim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
        isInteraction: true,
      }).start();
    }
  }, [idx, activeIdx]);

  // Estilos animados
  const style = {
    opacity: anim.interpolate({
      inputRange: [0.3, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    left: (tabWidth - 48) / 2 + tabWidth * idx,
    transform: [{ translateY: anim.interpolate({ inputRange: [0, 1], outputRange: [64, 0] }) }],
  };

  return <Root style={style}>{children}</Root>;
};

const Root = styled(Animated.View)`
  position: absolute;
  top: -12px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.primary.darken(0.2).desaturate(0.2)}`};
  border-radius: 24px;
  width: 48px;
  height: 48px;
`;

export default ActiveIcon;
