import React from 'react';
import { ActivityIndicator } from 'react-native';

import { useTheme } from 'styled-components/native';

import { theme } from '$theme';

interface Props {
  size?: 'small' | 'large' | number;
  margin?: number;
  color?: string;
}

const Loader: React.FC<Props> = ({ size, margin, color }) => {
  const theme = useTheme();
  return <ActivityIndicator style={{ margin }} color={color ?? theme.primary.hex()} size={size} />;
};

Loader.defaultProps = {
  margin: 32,
};

export default Loader;
