import React from 'react';
import { Platform, View, ViewStyle } from 'react-native';

import LottieView from 'lottie-react-native';

interface Props {
  style: ViewStyle;
  source: string;
  autoPlay?: boolean;
}

const Lottie: React.FC<Props> = ({ style, source, autoPlay }) => {
  if (Platform.OS === 'web') {
    const ref = React.useRef();

    React.useLayoutEffect(() => {
      const lottie = require('lottie-web');

      lottie.loadAnimation({
        animationData: source,
        renderer: 'svg',
        container: ref.current,
        loop: true,
        autoplay: autoPlay,
      });
    }, []);

    return <View ref={ref} style={style} />;
  }

  return (
    <View style={style}>
      <LottieView autoPlay={autoPlay} source={source} />
    </View>
  );
};

export default Lottie;
