import React from 'react';

import moment, { Moment } from 'moment';
import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Text from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { loadData, storeData } from '$storage';

interface Props {
  persist?: string;
  onChange?: (month: Moment) => void;
}

const MonthFilter: React.FC<Props> = ({ persist, onChange }) => {
  const [month, setMonth] = React.useState<Moment>(moment());

  function notifyOnChange(value: Moment) {
    if (!onChange) return;
    onChange(value);
  }

  React.useEffect(() => {
    if (!persist) return notifyOnChange(month);

    loadData<string>(`hf_${persist}`).then((data) => {
      if (!data) return notifyOnChange(month);
      setMonth(moment(data));
      notifyOnChange(moment(data));
    });
  }, []);

  function handleSubtract() {
    const m = moment(month).subtract({ month: 1 });
    setMonth(m);
    notifyOnChange(m);
    if (persist) storeData(`hf_${persist}`, m.toISOString());
  }

  function handleAdd() {
    const m = moment(month).add({ month: 1 });
    setMonth(m);
    notifyOnChange(m);
    if (persist) storeData(`hf_${persist}`, m.toISOString());
  }

  return (
    <Root>
      <Content>
        <Touchable onPress={handleSubtract}>
          <ChangeButton>
            <AntDesign name="left" color="#FFF" size={16} />
          </ChangeButton>
        </Touchable>

        <Text centered h3 bold paddedY style={{ flex: 1 }}>
          {month.format('MMMM [de] YYYY')}
        </Text>

        <Touchable onPress={handleAdd}>
          <ChangeButton>
            <AntDesign name="right" color="#FFF" size={16} />
          </ChangeButton>
        </Touchable>
      </Content>
    </Root>
  );
};

const Root = styled.View`
  margin: 0 8px;
  flex: 1;
  width: 100%;
`;

const Content = styled.View`
  flex-direction: row;
  background-color: #00000060;
  border-radius: 4px;
  overflow: hidden;
  min-height: 40px;
`;

const ChangeButton = styled.View`
  width: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary.hex()};
`;

export default MonthFilter;
