import React from 'react';
import { Platform, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';

import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import styled from 'styled-components/native';

import { AntDesign, Feather, FontAwesome } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Progress from '$components/Feedback/Progress';
import HorizontalFilter from '$components/Filter/HorizontalFilter';
import MonthFilter from '$components/Filter/MonthFilter';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useAccountLeadsLazyQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

const Leads: React.FC = () => {
  const store = useStore();

  const [filters, setFilters] = React.useState<string[]>();
  const [month, setMonth] = React.useState<Moment>();

  const [fetchLeads, { data, loading, error, refetch }] = useAccountLeadsLazyQuery();

  React.useEffect(() => {
    if (!store.badgeIsDirty) return;
    refetch();
  }, [store.badgeIsDirty]);

  React.useEffect(() => {
    if (!store.selectedAccountId || !month) return;

    fetchLeads({
      variables: {
        account: store.selectedAccountId,
        begin: month.startOf('month').format('YYYY-MM-DD'),
        end: month.endOf('month').format('YYYY-MM-DD'),
      },
    });
  }, [store.selectedAccountId, month?.toISOString()]);

  React.useEffect(() => {
    if (!store.mustRefetchLeads || !refetch) return;
    store.setMustRefetchleads(false);
    refetch();
  }, [store.mustRefetchLeads]);

  const processedLeads = [...(data?.account.leads.data ?? [])];

  const filteredLeads = processedLeads.filter(
    (lead) => filters?.length === 0 || filters?.indexOf(lead.classification) !== -1,
  );

  const stats = processedLeads.reduce(
    (prev, curr) => {
      return { ...prev, [curr.classification]: (prev[curr.classification] ?? 0) + 1 };
    },
    {
      none: 0,
      no_contact: 0,
      has_profile: 0,
      no_profile: 0,
      closed: 0,
    },
  );

  const classificationIcons: any = {
    none: <AntDesign name="questioncircleo" />,
    no_contact: <Feather name="user-x" size={16} />,
    has_profile: <Feather name="user-check" size={16} />,
    no_profile: <Feather name="user" size={16} />,
    closed: <FontAwesome name="money" size={16} />,
  };

  const sourceIcons: any = {
    none: <FontAwesome name="user-circle-o" />,
    facebook: <FontAwesome name="facebook-official" />,
    whatsapp: <FontAwesome name="whatsapp" />,
    website: <FontAwesome name="globe" />,
    linkedin: <FontAwesome name="linkedin-square" />,
  };

  const colors: any = {
    none: '#EC4C72',
    no_contact: '#d64848',
    has_profile: '#0f81a3',
    no_profile: '#ff6f1b',
    closed: '#09b27f',
  };

  const handleChangeFilter = () => {
    navigate('LeadsFilter');
  };

  return (
    <Screen style={{ overflow: 'scroll' }}>
      <Filters style={{ ...getMaxConstraints() }}>
        <FiltersContainer>
          <MonthFilter persist="leads_month" onChange={(month) => setMonth(month)} />
          <Touchable onPress={handleChangeFilter}>
            <ChangeButton>
              <AntDesign name="filter" color="#FFF" size={16} />
            </ChangeButton>
          </Touchable>
        </FiltersContainer>

        <Progress
          checked={filters}
          values={[
            { key: 'none', value: stats.none, color: colors.none },
            { key: 'no_contact', value: stats.no_contact, color: colors.no_contact },
            { key: 'has_profile', value: stats.has_profile, color: colors.has_profile },
            { key: 'no_profile', value: stats.no_profile, color: colors.no_profile },
            { key: 'closed', value: stats.closed, color: colors.closed },
          ]}
        />

        <HorizontalFilter
          multiple
          persist="leads_step"
          onChange={(options) => setFilters(options)}
          options={[
            {
              key: 'none',
              value: stats.none,
              label: 'Não contatado',
              icon: classificationIcons.none,
              color: colors.none,
            },
            {
              key: 'no_contact',
              value: stats.no_contact,
              label: 'Sem contato',
              icon: classificationIcons.no_contact,
              color: colors.no_contact,
            },
            {
              key: 'has_profile',
              value: stats.has_profile,
              label: 'Com perfil',
              icon: classificationIcons.has_profile,
              color: colors.has_profile,
            },
            {
              key: 'no_profile',
              value: stats.no_profile,
              label: 'Sem perfil',
              icon: classificationIcons.no_profile,
              color: colors.no_profile,
            },
            {
              key: 'closed',
              value: stats.closed,
              label: 'Fechado',
              icon: classificationIcons.closed,
              color: colors.closed,
            },
          ]}
        />
      </Filters>

      {loading && <Loader size={80} />}

      <View style={{ ...getMaxConstraints() }}>
        {error && <Alert error message="Ocorreu um erro ao carregar as atividades" description={error.message} />}

        {!loading && !filteredLeads.length && <Alert spaced message="Nenhum lead encontrado para o filtro atual" />}
      </View>

      <View style={{ ...getMaxConstraints(), flex: 1 }}>
        <FlashList
          data={filteredLeads}
          keyExtractor={(lead) => lead.id}
          ItemSeparatorComponent={LeadSeparator}
          contentContainerStyle={{ paddingHorizontal: 8, paddingBottom: 72 }}
          scrollIndicatorInsets={{ bottom: 50 }}
          estimatedItemSize={70}
          renderItem={({ item: lead, index }) => {
            const classificationIcon = React.cloneElement(classificationIcons[lead.classification], {
              color: colors[lead.classification],
              size: 22,
            });

            const sourceIcon = React.cloneElement(sourceIcons[lead.source], { color: '#ffffff50', size: 12 });

            let sourceText = '';
            if (lead.source === 'whatsapp') sourceText = 'WhatsApp';
            if (lead.source === 'facebook') sourceText = 'Facebook';
            if (lead.source === 'linkedin') sourceText = 'Linkedin';
            if (lead.source === 'website') sourceText = 'Website';

            const subscribedAt = moment.utc(lead.subscribed_at, 'YYYY-MM-DD HH:mm:ss').local();

            const hasDateComponent =
              index === 0 ||
              !subscribedAt.isSame(
                moment.utc(filteredLeads[index - 1].subscribed_at, 'YYYY-MM-DD HH:mm:ss').local(),
                'day',
              );

            return (
              <>
                {hasDateComponent && (
                  <DateSeparator>
                    <Text h4 faded>
                      {subscribedAt.format('dddd[,] DD [de] MMMM YYYY')}
                    </Text>
                  </DateSeparator>
                )}

                <Touchable onPress={() => navigate('Lead', { id: lead.id })}>
                  <LeadRoot>
                    <LeadIcon>{classificationIcon}</LeadIcon>

                    <LeadContent>
                      <Text>{lead.name}</Text>

                      {lead.source !== 'none' && (
                        <LeadSource>
                          <LeadSourceIcon>{sourceIcon}</LeadSourceIcon>
                          <Text faded p3>
                            via {sourceText}
                          </Text>
                        </LeadSource>
                      )}
                    </LeadContent>

                    <LeadOpenIcon>
                      <AntDesign name="right" color="#FFFFFF80" size={18} />
                    </LeadOpenIcon>
                  </LeadRoot>
                </Touchable>
              </>
            );
          }}
        />
      </View>
    </Screen>
  );
};

export default observer(Leads);

const Filters = styled.View`
  padding: 8px 0 0;
  background-color: ${({ theme }) => theme.primary.hex()}30;
  margin-top: ${() => (Platform.OS === 'web' ? 12 : 0)}px;
  border-radius: ${() => (Platform.OS === 'web' ? 4 : 0)}px;
`;

const FiltersContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  width: 100%;
`;

const DateSeparator = styled.View`
  padding: 12px 0 4px;
`;

const LeadSeparator = styled.View`
  height: 6px;
`;

const LeadRoot = styled.View`
  flex-direction: row;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.primary.hex()}80;
  border-radius: 4px;
  align-items: center;
`;

const LeadIcon = styled.View`
  padding: 4px 12px 4px 4px;
`;

const LeadContent = styled.View`
  flex: 1;
  justify-content: center;
`;

const LeadSource = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LeadSourceIcon = styled.View`
  padding: 0 4px 0 0;
`;

const LeadOpenIcon = styled.View`
  padding-left: 8px;
`;

const ChangeButton = styled.View`
  width: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary.hex()};
  margin-right: 8px;
  border-radius: 4px;
`;
