import React from 'react';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import styled from 'styled-components/native';

import Text from '$components/General/Text';
import Container from '$components/Grid/Container';

import LogoImage from '$assets/logo.png';

import AccountSelector from './AccountSelector';
import Navigation, { Tab } from './Navigation';
import { getMaxConstraints } from '$logic/style';

interface Props {
  tabs: Tab[];
}

const BottomBar: React.FC<Props> = ({ tabs }) => {
  return (
    <Root edges={['top', 'left', 'right']}>
      <Container style={{ flexDirection: 'row', alignItems: 'center', ...getMaxConstraints() }}>
        <IDLogo source={LogoImage} />

        {Platform.OS === 'web' && (
          <Text h1 spacedRight={2}>
            ID Action
          </Text>
        )}

        <Navigation tabs={tabs} />

        <AccountSelector />
      </Container>
    </Root>
  );
};

const Root = styled(SafeAreaView)`
  background-color: ${({ theme }) => theme.primary.darken(0.3).desaturate(0.3).fade(0.2).hex()};
`;

const IDLogo = styled.Image`
  margin: 5px 8px;
  width: 38px;
  height: 38px;
`;

export default BottomBar;
