import { initReactI18next } from 'react-i18next';

import 'moment';
import 'moment/locale/pt-br';

import { registerRootComponent } from 'expo';
import * as Localization from 'expo-localization';
import i18n from 'i18next';

import en from './src/lang/en';
import pt from './src/lang/pt';
import Root from './src/Root';

// Inicia o i18n
i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: { /*en,*/ pt },
  lng: Localization.locale,
  fallbackLng: 'pt',
  interpolation: { escapeValue: false },
});

// Inicia o render do componente
registerRootComponent(Root);
