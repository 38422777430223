import React from 'react';

import color from 'color';
import { DefaultTheme, ThemeProvider } from 'styled-components/native';

export const theme: DefaultTheme = {
  primary: color('#21636e'),
  secondary: color('#F00'),
  success: color('#2c6733'),
  warning: color('#c0742d'),
  error: color('#9a3a36'),
  info: color('#0f81a3'),
};

const Theme: React.FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
