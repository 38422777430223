import React from 'react';
import { Platform, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';

import * as IntentLauncher from 'expo-intent-launcher';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import styled from 'styled-components/native';

import { AntDesign, MaterialIcons } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Button from '$components/General/Button';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useApprovalTasksQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

const Approval: React.FC = () => {
  const store = useStore();

  const [hasNotifications, setHasNotifications] = React.useState<boolean | undefined>(undefined);

  const begin = moment().subtract({ day: 10 }).format('YYYY-MM-DD');
  const end = moment().add({ day: 10 }).format('YYYY-MM-DD');

  const { data, loading, error, refetch } = useApprovalTasksQuery({
    variables: { account: store.selectedAccountId ?? '', begin, end },
  });

  React.useEffect(() => {
    if (!store.badgeIsDirty) return;
    refetch();
  }, [store.badgeIsDirty]);

  React.useEffect(() => {
    Notifications.getPermissionsAsync().then((ret) =>
      setHasNotifications(ret.granted || ret.ios?.status == Notifications.IosAuthorizationStatus.PROVISIONAL),
    );
  }, []);

  function handleGetPermission() {
    Notifications.requestPermissionsAsync().then((ret) => {
      const granted = ret.granted || ret.ios?.status == Notifications.IosAuthorizationStatus.PROVISIONAL;
      setHasNotifications(true);

      if (!granted) {
        if (Platform.OS === 'android') {
          IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS, {
            data: 'package:com.unidcom.idaction',
          });
        } else if (Platform.OS === 'ios') {
          Linking.openURL('app-settings:');
        }
      }
    });
  }

  const approval = [...(data?.approval.tasks.data ?? [])];

  const next = [...(data?.next.tasks.data ?? [])].sort((x, y) => {
    const xDate = moment(x.finish_date, 'YYYY-MM-DD');
    const yDate = moment(y.finish_date, 'YYYY-MM-DD');

    if (xDate.isBefore(yDate)) return -1;
    if (yDate.isBefore(xDate)) return 1;
    return 0;
  });

  const hasApproval = approval.length > 0;
  const hasNext = next.length > 0;

  return (
    <Screen style={{ overflow: 'scroll' }}>
      <View style={{ ...getMaxConstraints(), flex: 1 }}>
        <FlashList
          estimatedItemSize={56}
          data={hasApproval ? approval : next}
          keyExtractor={(task) => task.id}
          ItemSeparatorComponent={TaskSeparator}
          contentContainerStyle={{ paddingHorizontal: 8, paddingBottom: 72 }}
          scrollIndicatorInsets={{ bottom: 50 }}
          ListHeaderComponent={
            <>
              {hasNotifications === false && (
                <Alert
                  info
                  onPress={handleGetPermission}
                  icon={<MaterialIcons name="notifications-active" />}
                  message="Ative as notificações e não perca nada"
                  description="Você não está recebendo notificações de atividades. Clique aqui e ative para ficar por dentro de tudo que precisa ser aprovado."
                />
              )}

              {!loading && hasApproval && (
                <>
                  <Alert
                    warning
                    spacedTop
                    message="Não deixe para depois!"
                    description="Suas aprovações são essenciais para a estratégia de marketing da sua empresa."
                  />

                  <Text h3 faded spacedTop={4}>
                    Atividades para aprovação
                  </Text>
                  <Text disabled>Não deixe para depois, envie seu feedback agora!</Text>
                </>
              )}

              {!loading && !hasApproval && (
                <Alert
                  spacedTop
                  success
                  message="Parabéns!"
                  description="No momento não existe nenhuma atividade pendente para aprovação."
                />
              )}

              {!loading && !hasApproval && hasNext && (
                <>
                  <Text h3 faded spacedTop={4}>
                    Próximas atividades
                  </Text>
                  <Text disabled>Abaixo algumas atividades que virão nos próximos dias!</Text>
                </>
              )}

              {loading && <Loader size={80} />}
              {error && <Alert error message="Ocorreu um erro ao carregar as atividades" description={error.message} />}
            </>
          }
          ListHeaderComponentStyle={{ paddingVertical: 8 }}
          ListFooterComponent={
            !hasApproval && hasNext ? (
              <Button spacedY padded onPress={() => navigate('Tasks')}>
                Ver planejamento completo
              </Button>
            ) : undefined
          }
          renderItem={({ item: task, index }) => {
            const hasDateComponent = !hasApproval && (index === 0 || task.finish_date !== next[index - 1].finish_date);

            let icon = 'warning';
            let iconColor = '#d64848';

            if (!hasApproval) {
              if (moment(task.finish_date, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
                icon = 'clockcircleo';
                iconColor = '#ff6f1b';
              } else {
                icon = 'calendar';
                iconColor = '#0f81a3';
              }
            }

            return (
              <>
                {hasDateComponent && (
                  <DateSeparator>
                    <Text h4 faded>
                      {moment(task.finish_date, 'YYYY-MM-DD').format('dddd[,] DD [de] MMMM')}
                    </Text>
                  </DateSeparator>
                )}

                <Touchable onPress={() => navigate('Task', { id: task.id })}>
                  <TaskRoot>
                    <TaskIcon>
                      <AntDesign name={icon as any} color={iconColor} size={22} />
                    </TaskIcon>

                    <Text style={{ flex: 1 }}>{task.title}</Text>

                    <TaskOpenIcon>
                      <AntDesign name="right" color="#FFFFFF80" size={18} />
                    </TaskOpenIcon>
                  </TaskRoot>
                </Touchable>
              </>
            );
          }}
        />
      </View>
    </Screen>
  );
};

export default observer(Approval);

const HeaderContent = styled.View`
  padding: 8px;
`;

const HeadsUpRoot = styled.View`
  background-color: #00000040;
  border-radius: 4px;
  padding: 12px;
`;

const TaskSeparator = styled.View`
  height: 6px;
`;

const DateSeparator = styled.View`
  padding: 12px 0 4px;
`;

const TaskRoot = styled.View`
  flex-direction: row;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.primary.hex()}80;
  border-radius: 4px;
  align-items: center;
`;

const TaskIcon = styled.View`
  padding: 4px 12px 4px 4px;
`;

const TaskOpenIcon = styled.View`
  padding-left: 8px;
`;
