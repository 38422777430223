import React from 'react';
import { Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import moment from 'moment';
import styled from 'styled-components';

import Input from '../Input';

interface InputDateProps {
  placeholder?: string;
  value?: string;
  onChange: (date: string) => void;
}

function InputDate({ placeholder, value, onChange }: InputDateProps) {
  const [isDatePickerVisible, setDatePickerVisibility] = React.useState(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date: any) => {
    hideDatePicker();
    onChange(date ? moment(date.toString()).format('YYYY-MM-DD') : '');
  };

  const getDate = (date?: string) => {
    if (date?.toString()) return moment(date.toString()).toDate();

    return moment(new Date()).toDate();
  };

  const convertFormat = (date?: string) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const convertFormatDate = (date?: string) => {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  };

  if (Platform.OS === 'web')
    return (
      <WebInputDate
        tag="input"
        type="date"
        value={value ? convertFormat(value) : ''}
        onInput={({ target: { value: date } }: any) => onChange(date ? convertFormat(date) : '')}
      />
    );

  return (
    <>
      <TouchableOpacity onPress={showDatePicker}>
        <View pointerEvents="none">
          <Input placeholder={placeholder} value={convertFormatDate(value)} editable={false} />
        </View>
      </TouchableOpacity>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="date"
        isDarkModeEnabled={true}
        themeVariant="dark"
        date={getDate(value)}
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
      />
    </>
  );
}

const WebInputDate = styled(({ tag, children, ...props }) => React.createElement(tag, props, children))`
  padding: 8px 12px;
  background-color: #00000060;
  height: 40px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  border-radius: 2px;
  outline-style: none;
  border: 0px solid black;
  box-sizing: border-box;
  font-family: 'Nunito_400Regular';
  width: 100%;
`;

export default InputDate;
