import React from 'react';
import { Platform } from 'react-native';
import { ResizeMode, Video } from 'expo-av';
import styled from 'styled-components/native';

import Loader from '$components/Feedback/Loader';

interface Props {
  url: string;
}

const VideoComp: React.FC<Props> = ({ url }) => {
  const [loading, setLoading] = React.useState(true);

  const videoRef = React.useRef<Video>(null);

  const handleLoad = React.useCallback(() => {
    setLoading(false);
    videoRef.current?.playAsync();
  }, []);

  return (
    <>
      {loading && (
        <LoaderView>
          <Loader size="large" />
        </LoaderView>
      )}

      <Video
        ref={videoRef}
        useNativeControls
        videoStyle={Platform.OS === 'web' ? { position: 'relative' } : undefined}
        style={{ flex: 1, opacity: loading ? 0 : 1 }}
        source={{ uri: url }}
        resizeMode={ResizeMode.CONTAIN}
        onLoad={handleLoad}
      />
    </>
  );
};

export default VideoComp;

const LoaderView = styled.View`
  position: absolute;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`;
