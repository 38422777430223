import React from 'react';
import { useWindowDimensions } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';

import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import { getNavigation, navigate } from '$navigation';

import { Tab } from '../TopBar/Navigation';
import ActiveIcon from './ActiveIcon';
import Badge from './Badge';
import InactiveIcon from './InactiveIcon';
import MovableBarNotch from './MovableBarNotch';

interface Props {
  tabs: Tab[];
}

const BottomBar: React.FC<Props> = ({ tabs }) => {
  const nav = getNavigation();

  // Define constants
  const dim = useWindowDimensions();
  const { width } = dim;

  const tabWidth = React.useMemo(() => width / tabs.length, [width]);
  const tabHeight = 50;
  const curveWidth = 24;

  // Navigation events
  React.useEffect(() => {
    if (!nav) return;

    function handler(state: any) {
      if (!nav) return;
      const idx = tabs.findIndex((tab) => tab.screen === nav.getCurrentRoute()?.name);
      if (idx > -1) setCurrIdx(idx);
    }

    nav.addListener('state', handler);
    return () => nav.removeListener('state', handler);
  }, [nav]);

  // Current active tab idxs
  const [currIdx, setCurrIdx] = React.useState(0);

  const handlePress = React.useCallback(
    (idx) => {
      // Clamp idx
      if (idx < 0) idx = 0;
      if (idx > tabs.length - 1) idx = tabs.length - 1;
      setCurrIdx(idx);

      navigate(tabs[idx].screen);
    },
    [width, tabWidth, setCurrIdx]
  );

  const handlePanGesture = React.useCallback(
    (e) => {
      const { translationX } = e.nativeEvent;
      const tabs = Math.round(Math.abs(translationX / tabWidth));

      if (tabs > 0) {
        if (translationX < 0) handlePress(currIdx + tabs);
        else handlePress(currIdx - tabs);
      }
    },
    [handlePress]
  );

  return (
    <PanGestureHandler onHandlerStateChange={handlePanGesture}>
      <Root>
        <MovableBarNotch
          activeIdx={currIdx}
          width={width}
          tabHoleWidth={80}
          tabWidth={tabWidth}
          tabHeight={tabHeight}
          curveWidth={curveWidth}
        />

        <IconsRow style={{ width, height: tabHeight }}>
          {tabs.map((tab, idx) => {
            return (
              <React.Fragment key={idx}>
                <InactiveIcon idx={idx} activeIdx={currIdx} tabHeight={tabHeight} handlePress={handlePress}>
                  <AntDesign size={24} name={tab.icon as any} color="#999" />
                  <Badge
                    badge={tab.badge}
                    small
                    style={{ bottom: 12, left: tabWidth / 2 + 6, backgroundColor: '#f66' }}
                  />
                </InactiveIcon>

                <ActiveIcon idx={idx} activeIdx={currIdx} tabWidth={tabWidth}>
                  <AntDesign size={28} name={tab.icon as any} color="#ddd" />
                  <Badge badge={tab.badge} style={{ top: -6, left: 32, backgroundColor: '#c22' }} />
                </ActiveIcon>
              </React.Fragment>
            );
          })}
        </IconsRow>
      </Root>
    </PanGestureHandler>
  );
};

const Root = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const IconsRow = styled.View`
  position: absolute;
  flex-direction: row;
`;

export default BottomBar;
