import AsyncStorage from '@react-native-async-storage/async-storage';

export async function storeData(key: string, data: any = {}): Promise<boolean> {
  try {
    await AsyncStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export async function loadData<T>(key: string): Promise<T | undefined> {
  try {
    const data = await AsyncStorage.getItem(key);
    if (!data) return undefined;

    try {
      return JSON.parse(data);
    } catch (err) {
      return data as any;
    }
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

export async function eraseData(key: string): Promise<boolean> {
  try {
    await AsyncStorage.removeItem(key);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
