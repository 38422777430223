import styled from 'styled-components/native';

interface SelectViewProps {
  isSelected: boolean;
}

interface OptionSelectedProps {
  changeColorOptionSelected?: boolean;
}

interface TextSelectProps {
  isExistChosen: boolean;
}

export const ContainerModal = styled.Pressable`
  flex: 1;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: flex-end;
`;

export const CloseContainer = styled.View`
  align-items: center;
  margin-bottom: 33px;
`;

export const CloseModal = styled.TouchableOpacity`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.primary.darken(0.45).hex()};
  align-items: center;
  justify-content: center;
  border-radius: 50px;
`;

export const Wrapper = styled.View`
  background-color: ${({ theme }) => theme.primary.darken(0.45).hex()};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 150px;
  max-height: 270px;
`;

export const SelectTouchableInput = styled.TouchableOpacity<SelectViewProps>`
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #00000060;
  min-height: 40px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  border-radius: 2px;
`;

export const TextSelect = styled.Text<TextSelectProps>`
  left: -5px;
  color: ${(props) =>
    props.isExistChosen ? `#FFFFFF` : `rgba(255, 255, 255, 0.87)`};
  font-size: 14px;
  width: 265px;
`;

export const Option = styled.TouchableOpacity`
  width: 100%;
  height: 60px;
  justify-content: center;
`;

export const OptionText = styled.Text<OptionSelectedProps>`
  color: #FFFFFF;
  padding: 0 20px;
  font-size: 18px;
`;
