import React from 'react';
import { View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import PaginationDot from 'react-native-animated-pagination-dot';

import styled, { useTheme } from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Loader from '$components/Feedback/Loader';
import Touchable from '$components/Navigation/Touchable';

import { goBack } from '$navigation';

interface Props {
  route: any;
}

const ImagesViewer: React.FC<Props> = ({ route }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const [active, setActive] = React.useState(route.params.index ?? 0);

  return (
    <Root style={{ paddingBottom: 72 + insets.bottom }}>
      <ImageViewer
        enablePreload
        enableSwipeDown
        renderHeader={() => (
          <CloseButtonView>
            <Touchable onPress={() => goBack()}>
              <AntDesign name="closecircle" color="#dddddd" size={22} />
            </Touchable>
          </CloseButtonView>
        )}
        footerContainerStyle={{ top: 0, right: 0, bottom: undefined }}
        index={route.params.index}
        imageUrls={route.params.images.map((image: string) => ({ url: image }))}
        onCancel={() => goBack()}
        loadingRender={() => <Loader />}
        renderIndicator={() => <></>}
        onChange={setActive}
      />

      <View style={{ alignItems: 'center' }}>
        <PaginationDot
          sizeRatio={1.5}
          activeDotColor={theme.primary.hex()}
          curPage={active}
          maxPage={route.params.images.length}
        />
      </View>
    </Root>
  );
};

const Root = styled.View`
  flex: 1;
  background-color: #000;
`;

const CloseButtonView = styled.View`
  position: absolute;
  z-index: 9999;
  top: 16px;
  right: 16px;
`;

export default ImagesViewer;
