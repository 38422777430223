import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  api_key?: Maybe<Scalars['String']['output']>;
  cells: CellPaginator;
  customers: UserPaginator;
  devices: Array<Device>;
  facebook?: Maybe<ThirdPartyAccount>;
  forms: FormPaginator;
  google?: Maybe<ThirdPartyAccount>;
  id: Scalars['ID']['output'];
  infos: AccountInfoPaginator;
  journey?: Maybe<AccountJourney>;
  leads: LeadPaginator;
  links: LinkPaginator;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mailerlite_apikey?: Maybe<Scalars['String']['output']>;
  mailerlite_fields?: Maybe<Array<MailerliteField>>;
  mailerlite_groups?: Maybe<Array<MailerliteGroup>>;
  members: UserPaginator;
  /** Tempo mensal total contratado para a conta */
  monthly_planned_seconds?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  piperun_funnelhash?: Maybe<Scalars['String']['output']>;
  realm: Realm;
  stats: AccountStats;
  status: AccountStatus;
  summary: AccountSummary;
  tasks: TaskPaginator;
  templates: TemplatePaginator;
  timers: TimerPaginator;
  timers_report?: Maybe<AccountTimersReport>;
  /** Tempo total usado em atividades da conta */
  timers_total_seconds: Scalars['Int']['output'];
  users: UserPaginator;
};


export type AccountCellsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AccountCustomersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AccountFormsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AccountInfosArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AccountLeadsArgs = {
  filter?: InputMaybe<LeadFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AccountLinksArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AccountMembersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AccountTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AccountTemplatesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AccountTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AccountTimers_ReportArgs = {
  period: DatePeriod;
};


export type AccountTimers_Total_SecondsArgs = {
  period?: InputMaybe<DatePeriod>;
};


export type AccountUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type AccountInfo = {
  __typename?: 'AccountInfo';
  account: Account;
  body?: Maybe<Scalars['String']['output']>;
  files: FilePaginator;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};


export type AccountInfoFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AccountInfoPaginator = Paginator & {
  __typename?: 'AccountInfoPaginator';
  count: Scalars['Int']['output'];
  data: Array<AccountInfo>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AccountInsightsData = {
  __typename?: 'AccountInsightsData';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cpc: Scalars['Float']['output'];
  cpm: Scalars['Float']['output'];
  cpp: Scalars['Float']['output'];
  ctr: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  frequency: Scalars['Float']['output'];
  impressions: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  spend: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export enum AccountJourney {
  ChannelProof = 'channel_proof',
  Implantation = 'implantation',
  NewJourney = 'new_journey',
  Otimization = 'otimization',
  Scale = 'scale',
  Validation = 'validation'
}

export type AccountPaginator = Paginator & {
  __typename?: 'AccountPaginator';
  count: Scalars['Int']['output'];
  data: Array<Account>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AccountStats = {
  __typename?: 'AccountStats';
  leads: LeadsStats;
  tasks: TasksStats;
};


export type AccountStatsLeadsArgs = {
  filter?: InputMaybe<LeadFilter>;
};


export type AccountStatsTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
};

export enum AccountStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended'
}

export type AccountSummary = {
  __typename?: 'AccountSummary';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
};

/** Informações sobre timers de uma conta */
export type AccountTimersReport = {
  __typename?: 'AccountTimersReport';
  monthly_planned_seconds: Scalars['Int']['output'];
  planned_seconds: Scalars['Int']['output'];
  tasks: Scalars['Int']['output'];
  total_seconds: Scalars['Int']['output'];
};

export type ApprovalInfo = {
  __typename?: 'ApprovalInfo';
  accepted?: Maybe<Scalars['Int']['output']>;
  month_customer_accepted?: Maybe<Scalars['Int']['output']>;
  month_total_accepted?: Maybe<Scalars['Int']['output']>;
  rejected?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AssignmentTask = Task & {
  __typename?: 'AssignmentTask';
  account: Account;
  account_id: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  cell?: Maybe<Cell>;
  cell_id?: Maybe<Scalars['ID']['output']>;
  events: TaskEventPaginator;
  files: FilePaginator;
  finish_date?: Maybe<Scalars['Date']['output']>;
  from_template?: Maybe<Template>;
  from_template_item?: Maybe<TemplateItem>;
  id: Scalars['ID']['output'];
  notes: NotePaginator;
  parent?: Maybe<Task>;
  parent_id?: Maybe<Scalars['ID']['output']>;
  /** Tempo planejado para execução da atividade */
  planned_seconds?: Maybe<Scalars['Int']['output']>;
  pos?: Maybe<Scalars['Int']['output']>;
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  step: TaskStep;
  subtasks: TaskPaginator;
  timers: TimerPaginator;
  /** Tempo total usado na atividade */
  timers_total_seconds: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: TaskType;
  users?: Maybe<Array<Maybe<User>>>;
};


export type AssignmentTaskEventsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AssignmentTaskFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AssignmentTaskNotesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AssignmentTaskSubtasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type AssignmentTaskTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type AttachFile = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum AttachType {
  Account = 'Account',
  Cell = 'Cell',
  Task = 'Task'
}

export enum AvatarTarget {
  Account = 'Account',
  Cell = 'Cell',
  User = 'User'
}

export type Cell = {
  __typename?: 'Cell';
  accounts: AccountPaginator;
  customers: UserPaginator;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  members: UserPaginator;
  name: Scalars['String']['output'];
  realm: Realm;
  tasks: TaskPaginator;
  users: UserPaginator;
};


export type CellAccountsArgs = {
  pagination?: InputMaybe<Pagination>;
  status?: InputMaybe<Array<AccountStatus>>;
};


export type CellCustomersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type CellMembersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type CellTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type CellUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type CellPaginator = Paginator & {
  __typename?: 'CellPaginator';
  count: Scalars['Int']['output'];
  data: Array<Cell>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Checklist = {
  __typename?: 'Checklist';
  id: Scalars['ID']['output'];
  items: Array<ChecklistItem>;
  name: Scalars['String']['output'];
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  body: Scalars['String']['output'];
  checked: Scalars['Boolean']['output'];
  checklist: Checklist;
  id: Scalars['ID']['output'];
};

export type DatePeriod = {
  begin?: InputMaybe<Scalars['Date']['input']>;
  end?: InputMaybe<Scalars['Date']['input']>;
};

export type DefaultLink = Link & {
  __typename?: 'DefaultLink';
  account: Account;
  account_id: Scalars['ID']['output'];
  dest: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  subdomain: Scalars['String']['output'];
  type: LinkType;
  url: Scalars['String']['output'];
};

export type DefaultTask = Task & {
  __typename?: 'DefaultTask';
  account: Account;
  account_id: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  cell?: Maybe<Cell>;
  cell_id?: Maybe<Scalars['ID']['output']>;
  check_problem_count?: Maybe<Scalars['Int']['output']>;
  customer_problem_count?: Maybe<Scalars['Int']['output']>;
  events: TaskEventPaginator;
  execution_date?: Maybe<Scalars['Date']['output']>;
  feedbacks: FeedbackPaginator;
  files: FilePaginator;
  finish_date?: Maybe<Scalars['Date']['output']>;
  from_template?: Maybe<Template>;
  from_template_item?: Maybe<TemplateItem>;
  has_problem: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  notes: NotePaginator;
  parent?: Maybe<Task>;
  parent_id?: Maybe<Scalars['ID']['output']>;
  /** Tempo planejado para execução da atividade */
  planned_seconds?: Maybe<Scalars['Int']['output']>;
  pos?: Maybe<Scalars['Int']['output']>;
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  step: TaskStep;
  subtasks: TaskPaginator;
  timers: TimerPaginator;
  /** Tempo total usado na atividade */
  timers_total_seconds: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: TaskType;
  users?: Maybe<Array<Maybe<User>>>;
};


export type DefaultTaskEventsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type DefaultTaskFeedbacksArgs = {
  filter?: InputMaybe<FeedbackFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type DefaultTaskFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type DefaultTaskNotesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type DefaultTaskSubtasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type DefaultTaskTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type Device = {
  __typename?: 'Device';
  device_id: Scalars['String']['output'];
  expo_push_token?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  user: User;
};

export type DevicePaginator = Paginator & {
  __typename?: 'DevicePaginator';
  count: Scalars['Int']['output'];
  data: Array<Device>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FacebookAccount = {
  __typename?: 'FacebookAccount';
  accounts: AccountPaginator;
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  ext_accounts: Array<FacebookExtAccount>;
  ext_id: Scalars['String']['output'];
  has_problem: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type FacebookAccountAccountsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type FacebookAccountPaginator = Paginator & {
  __typename?: 'FacebookAccountPaginator';
  count: Scalars['Int']['output'];
  data: Array<FacebookAccount>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FacebookExtAccount = {
  __typename?: 'FacebookExtAccount';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  account: Account;
  account_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  files: FilePaginator;
  id: Scalars['ID']['output'];
  nps?: Maybe<Scalars['Int']['output']>;
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  source?: Maybe<FeedbackSource>;
  status?: Maybe<FeedbackStatus>;
  task: Task;
  task_id: Scalars['ID']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};


export type FeedbackFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type FeedbackFilter = {
  source?: InputMaybe<Array<FeedbackSource>>;
  status?: InputMaybe<Array<FeedbackStatus>>;
};

export type FeedbackPaginator = Paginator & {
  __typename?: 'FeedbackPaginator';
  count: Scalars['Int']['output'];
  data: Array<Feedback>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FeedbackSource {
  Customer = 'customer',
  CustomerSuccess = 'customer_success'
}

export enum FeedbackStatus {
  Resolved = 'resolved',
  Unresolved = 'unresolved'
}

export type File = {
  __typename?: 'File';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  info: FileInfo;
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
  opt_info?: Maybe<FileInfo>;
  pos?: Maybe<Scalars['Int']['output']>;
  status: FileStatus;
  task?: Maybe<Task>;
  thumbnailUrl: Scalars['String']['output'];
};

export type FileInfo = {
  __typename?: 'FileInfo';
  mimetype: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type FilePaginator = Paginator & {
  __typename?: 'FilePaginator';
  count: Scalars['Int']['output'];
  data: Array<File>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FileStatus {
  Done = 'done',
  Failed = 'failed',
  Processing = 'processing',
  Uploaded = 'uploaded',
  Uploading = 'uploading'
}

export enum FileTarget {
  AccountInfo = 'AccountInfo',
  Feedback = 'Feedback',
  Note = 'Note',
  Task = 'Task'
}

export type Form = {
  __typename?: 'Form';
  account: Account;
  id: Scalars['ID']['output'];
  items: FormItemPaginator;
  name: Scalars['String']['output'];
};


export type FormItemsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type FormItem = {
  __typename?: 'FormItem';
  form: Form;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<FormSubitem>>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: FormItemType;
};

export type FormItemPaginator = Paginator & {
  __typename?: 'FormItemPaginator';
  count: Scalars['Int']['output'];
  data: Array<FormItem>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FormItemType {
  Checkbox = 'checkbox',
  Input = 'input',
  Select = 'select',
  Textarea = 'textarea'
}

export type FormPaginator = Paginator & {
  __typename?: 'FormPaginator';
  count: Scalars['Int']['output'];
  data: Array<Form>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FormSubitem = {
  __typename?: 'FormSubitem';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GoogleAccount = {
  __typename?: 'GoogleAccount';
  accounts: AccountPaginator;
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  ext_accounts: Array<GoogleExtAccount>;
  ext_id: Scalars['String']['output'];
  has_problem: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type GoogleAccountAccountsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type GoogleAccountPaginator = Paginator & {
  __typename?: 'GoogleAccountPaginator';
  count: Scalars['Int']['output'];
  data: Array<GoogleAccount>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type GoogleExtAccount = {
  __typename?: 'GoogleExtAccount';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ItemsInput = {
  items?: InputMaybe<Array<SubitemInput>>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: FormItemType;
};

export type Lead = {
  __typename?: 'Lead';
  account: Account;
  account_id: Scalars['ID']['output'];
  classification: LeadClassification;
  classified_at?: Maybe<Scalars['DateTime']['output']>;
  emails: Array<Scalars['String']['output']>;
  ext_id: Scalars['String']['output'];
  fields: Array<LeadField>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  phones: Array<Scalars['String']['output']>;
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  source: LeadSource;
  subscribed_at: Scalars['DateTime']['output'];
};

export enum LeadClassification {
  Closed = 'closed',
  HasProfile = 'has_profile',
  NoContact = 'no_contact',
  NoProfile = 'no_profile',
  None = 'none'
}

export type LeadClassificationStats = {
  __typename?: 'LeadClassificationStats';
  classification: LeadClassification;
  count: Scalars['Int']['output'];
};

export type LeadField = {
  __typename?: 'LeadField';
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LeadFilter = {
  classifications?: InputMaybe<Array<LeadClassification>>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<Array<LeadSource>>;
  subscribed_at?: InputMaybe<DatePeriod>;
};

export type LeadPaginator = Paginator & {
  __typename?: 'LeadPaginator';
  count: Scalars['Int']['output'];
  data: Array<Lead>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LeadSource {
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  None = 'none',
  Website = 'website',
  Whatsapp = 'whatsapp'
}

export type LeadSourceStats = {
  __typename?: 'LeadSourceStats';
  count: Scalars['Int']['output'];
  source: LeadSource;
};

export type LeadsStats = {
  __typename?: 'LeadsStats';
  classifications: Array<LeadClassificationStats>;
  sources: Array<LeadSourceStats>;
};

export type Link = {
  account: Account;
  account_id: Scalars['ID']['output'];
  dest: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  subdomain: Scalars['String']['output'];
  type: LinkType;
  url: Scalars['String']['output'];
};

export type LinkPaginator = Paginator & {
  __typename?: 'LinkPaginator';
  count: Scalars['Int']['output'];
  data: Array<Link>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LinkType {
  Default = 'default',
  Whatsapp = 'whatsapp'
}

export type MailerliteAccount = {
  __typename?: 'MailerliteAccount';
  ext_id: Scalars['ID']['output'];
  has_problem: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type MailerliteField = {
  __typename?: 'MailerliteField';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MailerliteGroup = {
  __typename?: 'MailerliteGroup';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFacebookAccount: Scalars['String']['output'];
  addGoogleAccount: Scalars['String']['output'];
  addMailerliteField: Account;
  approveTask: Task;
  attachAvatar?: Maybe<Scalars['String']['output']>;
  attachCell: Scalars['Boolean']['output'];
  attachFile?: Maybe<Scalars['String']['output']>;
  attachThirdPartyAccount: Account;
  attachUser: Scalars['Boolean']['output'];
  cloneTask: Task;
  convertLink: Link;
  convertTask: Task;
  createAccount?: Maybe<Account>;
  createAccountInfo: AccountInfo;
  createCell: Cell;
  createChecklist: Checklist;
  createChecklistItem: ChecklistItem;
  createForm: Form;
  createLink: Link;
  createNote: Note;
  createTask: Task;
  createTasksOfTemplateItems: Array<Task>;
  createTemplate: Template;
  createTemplateItem: TemplateItem;
  createTimer: Timer;
  createUploadUrl: UploadUrl;
  createUser: User;
  deleteAccount?: Maybe<Account>;
  deleteAccountInfo: AccountInfo;
  deleteCell: Cell;
  deleteChecklist: Checklist;
  deleteChecklistItem: ChecklistItem;
  deleteDeviceInfo: User;
  deleteFeedback: Feedback;
  deleteFile: Scalars['Boolean']['output'];
  deleteForm: Form;
  deleteLead: Scalars['Boolean']['output'];
  deleteLink: Scalars['Boolean']['output'];
  deleteMailerliteField: Account;
  deleteNote: Note;
  deleteNotification: Notification;
  deleteTask: Task;
  deleteTemplate: Template;
  deleteTemplateItem: TemplateItem;
  deleteTemplateItems: Scalars['Boolean']['output'];
  deleteTimer: Timer;
  deleteUser: User;
  detachAvatar: Scalars['Boolean']['output'];
  detachCell: Scalars['Boolean']['output'];
  detachThirdPartyAccount: Account;
  detachUser: Scalars['Boolean']['output'];
  linkMailerliteAccount: Account;
  login: Scalars['String']['output'];
  markAllNotificationsAsViewed: User;
  markNotificationAsViewed: Notification;
  moveAfter: Scalars['Boolean']['output'];
  moveBefore: Scalars['Boolean']['output'];
  recoverPassword: Scalars['Boolean']['output'];
  refreshApiKey: Account;
  rejectTask: Task;
  removeFacebookAccount: Scalars['Boolean']['output'];
  removeGoogleAccount: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendLinkInfo: Scalars['Boolean']['output'];
  setFeedbackStatus: Feedback;
  startTimer: Timer;
  stopTimer: Timer;
  syncLeads: Scalars['Boolean']['output'];
  unlinkMailerliteAccount: Account;
  updateAccount?: Maybe<Account>;
  updateAccountInfo: AccountInfo;
  updateCell: Cell;
  updateChecklist: Checklist;
  updateChecklistItem: ChecklistItem;
  updateDeviceInfo: User;
  updateFeedback: Feedback;
  updateForm: Form;
  updateLead: Lead;
  updateLink: Link;
  updateMailerliteApiKey: Account;
  updateNote: Note;
  updateProfile: User;
  updateRealm: Realm;
  updateTask: Task;
  updateTemplate: Template;
  updateTemplateItem: TemplateItem;
  updateTimer: Timer;
  updateUser: User;
  userFeedback: Scalars['Boolean']['output'];
};


export type MutationAddMailerliteFieldArgs = {
  account: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationApproveTaskArgs = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<AttachFile>>;
  id: Scalars['ID']['input'];
  nps?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAttachAvatarArgs = {
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  target: AvatarTarget;
};


export type MutationAttachCellArgs = {
  account: Scalars['ID']['input'];
  cell: Scalars['ID']['input'];
};


export type MutationAttachFileArgs = {
  file: AttachFile;
  id: Scalars['ID']['input'];
  target: FileTarget;
};


export type MutationAttachThirdPartyAccountArgs = {
  account: Scalars['ID']['input'];
  ext_account_id: Scalars['String']['input'];
  target: Scalars['ID']['input'];
  type: ThirdPartyAccountAttachType;
};


export type MutationAttachUserArgs = {
  target: Scalars['ID']['input'];
  type: AttachType;
  user: Scalars['ID']['input'];
};


export type MutationCloneTaskArgs = {
  account: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationConvertLinkArgs = {
  id: Scalars['ID']['input'];
  type: LinkType;
};


export type MutationConvertTaskArgs = {
  id: Scalars['ID']['input'];
  type: TaskType;
};


export type MutationCreateAccountArgs = {
  journey: AccountJourney;
  mailerlite_apikey?: InputMaybe<Scalars['String']['input']>;
  monthly_planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  status: AccountStatus;
};


export type MutationCreateAccountInfoArgs = {
  account: Scalars['ID']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateCellArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateChecklistArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateChecklistItemArgs = {
  body: Scalars['String']['input'];
  checklist: Scalars['ID']['input'];
};


export type MutationCreateFormArgs = {
  account: Scalars['ID']['input'];
  items: Array<ItemsInput>;
  name: Scalars['String']['input'];
};


export type MutationCreateLinkArgs = {
  account: Scalars['ID']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  dest: Scalars['String']['input'];
  ml_group_id?: InputMaybe<Scalars['String']['input']>;
  ml_sync?: InputMaybe<Scalars['Boolean']['input']>;
  path: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: LinkType;
};


export type MutationCreateNoteArgs = {
  body: Scalars['String']['input'];
  files?: InputMaybe<Array<AttachFile>>;
  task: Scalars['ID']['input'];
};


export type MutationCreateTaskArgs = {
  account: Scalars['ID']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  cell?: InputMaybe<Scalars['ID']['input']>;
  execution_date?: InputMaybe<Scalars['Date']['input']>;
  finish_date?: InputMaybe<Scalars['Date']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  step: TaskStep;
  title: Scalars['String']['input'];
  type: TaskType;
};


export type MutationCreateTasksOfTemplateItemsArgs = {
  account: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateTemplateArgs = {
  account?: InputMaybe<Scalars['ID']['input']>;
  cell?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateTemplateItemArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  template: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: TaskType;
};


export type MutationCreateTimerArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  finish_date: Scalars['DateTime']['input'];
  start_date: Scalars['DateTime']['input'];
  task: Scalars['ID']['input'];
};


export type MutationCreateUploadUrlArgs = {
  mimetype: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roles: Array<UserRole>;
  status: UserStatus;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAccountInfoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCellArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteChecklistArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteChecklistItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeviceInfoArgs = {
  device_id: Scalars['String']['input'];
};


export type MutationDeleteFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLeadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLinkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMailerliteFieldArgs = {
  account: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateItemsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteTimerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDetachAvatarArgs = {
  id: Scalars['ID']['input'];
  target: AvatarTarget;
};


export type MutationDetachCellArgs = {
  account: Scalars['ID']['input'];
  cell: Scalars['ID']['input'];
};


export type MutationDetachThirdPartyAccountArgs = {
  account: Scalars['ID']['input'];
  type: ThirdPartyAccountAttachType;
};


export type MutationDetachUserArgs = {
  target: Scalars['ID']['input'];
  type: AttachType;
  user: Scalars['ID']['input'];
};


export type MutationLinkMailerliteAccountArgs = {
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMarkNotificationAsViewedArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMoveAfterArgs = {
  id: Scalars['ID']['input'];
  target: Scalars['ID']['input'];
  type: SortableType;
};


export type MutationMoveBeforeArgs = {
  id: Scalars['ID']['input'];
  target: Scalars['ID']['input'];
  type: SortableType;
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationRefreshApiKeyArgs = {
  account: Scalars['ID']['input'];
};


export type MutationRejectTaskArgs = {
  feedback: Scalars['String']['input'];
  files?: InputMaybe<Array<AttachFile>>;
  id: Scalars['ID']['input'];
  nps?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRemoveFacebookAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveGoogleAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendLinkInfoArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationSetFeedbackStatusArgs = {
  id: Scalars['ID']['input'];
  status: FeedbackStatus;
};


export type MutationStartTimerArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationStopTimerArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSyncLeadsArgs = {
  account: Scalars['ID']['input'];
};


export type MutationUnlinkMailerliteAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID']['input'];
  journey?: InputMaybe<AccountJourney>;
  mailerlite_apikey?: InputMaybe<Scalars['String']['input']>;
  monthly_planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AccountStatus>;
};


export type MutationUpdateAccountInfoArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCellArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateChecklistArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateChecklistItemArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  checked?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateDeviceInfoArgs = {
  device_id: Scalars['String']['input'];
  expo_push_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFeedbackArgs = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  nps?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateFormArgs = {
  id: Scalars['ID']['input'];
  items: Array<ItemsInput>;
  name: Scalars['String']['input'];
};


export type MutationUpdateLeadArgs = {
  classification?: InputMaybe<LeadClassification>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLinkArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  dest?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  ml_group_id?: InputMaybe<Scalars['String']['input']>;
  ml_sync?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LinkType>;
};


export type MutationUpdateMailerliteApiKeyArgs = {
  account: Scalars['ID']['input'];
  mailerlite_apikey?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNoteArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateProfileArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRealmArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateTaskArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  cell?: InputMaybe<Scalars['ID']['input']>;
  execution_date?: InputMaybe<Scalars['Date']['input']>;
  finish_date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<TaskStep>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
};


export type MutationUpdateTemplateArgs = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateTemplateItemArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  planned_seconds?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
};


export type MutationUpdateTimerArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  finish_date?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationUpdateUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roles: Array<UserRole>;
  status?: InputMaybe<UserStatus>;
};


export type MutationUserFeedbackArgs = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

export type Note = {
  __typename?: 'Note';
  body: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  files: FilePaginator;
  id: Scalars['ID']['output'];
  task: Task;
  user: User;
};


export type NoteFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type NotePaginator = Paginator & {
  __typename?: 'NotePaginator';
  count: Scalars['Int']['output'];
  data: Array<Note>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Notification = {
  id: Scalars['ID']['output'];
  initiator: User;
  notified_at: Scalars['DateTime']['output'];
  recipient: User;
  type: NotificationType;
  viewed: Scalars['Boolean']['output'];
  viewed_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationFilter = {
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationPaginator = Paginator & {
  __typename?: 'NotificationPaginator';
  count: Scalars['Int']['output'];
  data: Array<Notification>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum NotificationType {
  TaskFeedbackCreated = 'task_feedback_created',
  TaskNoteCreated = 'task_note_created',
  TaskStepChanged = 'task_step_changed'
}

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Paginator = {
  count: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PeriodSelector {
  ExecutionDate = 'execution_date',
  FinishDate = 'finish_date'
}

export type Query = {
  __typename?: 'Query';
  account: Account;
  account_info: AccountInfo;
  approval_info?: Maybe<ApprovalInfo>;
  cell: Cell;
  file: File;
  files: FilePaginator;
  form: Form;
  insights: Array<AccountInsightsData>;
  lead: Lead;
  link: Link;
  linkSearch: Link;
  me: User;
  note: Note;
  notification: Notification;
  notifications: NotificationPaginator;
  realm: Realm;
  session: Session;
  task: Task;
  tasksChecking: TasksChecking;
  template: Template;
  templateItem: TemplateItem;
  user: User;
  userFromResetPasswordtoken: User;
};


export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAccount_InfoArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCellArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFilesArgs = {
  pagination?: InputMaybe<Pagination>;
  target: Scalars['ID']['input'];
  type: FileTarget;
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsightsArgs = {
  accountId: Scalars['ID']['input'];
  period: DatePeriod;
  type: Scalars['String']['input'];
};


export type QueryLeadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLinkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLinkSearchArgs = {
  path: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
};


export type QueryNoteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryRealmArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTasksCheckingArgs = {
  account?: InputMaybe<Scalars['ID']['input']>;
  cell?: InputMaybe<Scalars['ID']['input']>;
  period?: InputMaybe<DatePeriod>;
  user?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplateItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserFromResetPasswordtokenArgs = {
  token: Scalars['String']['input'];
};

export type Realm = {
  __typename?: 'Realm';
  accounts: AccountPaginator;
  cells: CellPaginator;
  customers: UserPaginator;
  facebook_accounts?: Maybe<FacebookAccountPaginator>;
  google_accounts?: Maybe<GoogleAccountPaginator>;
  id: Scalars['ID']['output'];
  members: UserPaginator;
  name: Scalars['String']['output'];
  status: RealmStatus;
  tasks: TaskPaginator;
  templates: TemplatePaginator;
  timers: TimerPaginator;
  users: UserPaginator;
};


export type RealmAccountsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type RealmCellsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type RealmCustomersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type RealmFacebook_AccountsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type RealmGoogle_AccountsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type RealmMembersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type RealmTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type RealmTemplatesArgs = {
  filter?: InputMaybe<TemplateFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type RealmTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type RealmUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type RealmPaginator = Paginator & {
  __typename?: 'RealmPaginator';
  count: Scalars['Int']['output'];
  data: Array<Realm>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RealmStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended'
}

export type Session = {
  __typename?: 'Session';
  token: Scalars['String']['output'];
  user: User;
};

export enum SortableType {
  File = 'File',
  Task = 'Task',
  Template = 'Template',
  TemplateItem = 'TemplateItem'
}

export type StepStats = {
  __typename?: 'StepStats';
  count: Scalars['Int']['output'];
  step: TaskStep;
};

export type SubitemInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Task = {
  account: Account;
  account_id: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  cell?: Maybe<Cell>;
  cell_id?: Maybe<Scalars['ID']['output']>;
  events: TaskEventPaginator;
  files: FilePaginator;
  finish_date?: Maybe<Scalars['Date']['output']>;
  from_template?: Maybe<Template>;
  from_template_item?: Maybe<TemplateItem>;
  id: Scalars['ID']['output'];
  notes: NotePaginator;
  parent?: Maybe<Task>;
  parent_id?: Maybe<Scalars['ID']['output']>;
  /** Tempo planejado para execução da atividade */
  planned_seconds?: Maybe<Scalars['Int']['output']>;
  pos?: Maybe<Scalars['Int']['output']>;
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  step: TaskStep;
  subtasks: TaskPaginator;
  timers: TimerPaginator;
  /** Tempo total usado na atividade */
  timers_total_seconds: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: TaskType;
  users?: Maybe<Array<Maybe<User>>>;
};


export type TaskEventsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type TaskFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type TaskNotesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type TaskSubtasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type TaskTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type TaskEvent = {
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
};

export enum TaskEventAction {
  Approve = 'approve',
  AttachUser = 'attach_user',
  ChangeBody = 'change_body',
  ChangeCell = 'change_cell',
  ChangeExecutionDate = 'change_execution_date',
  ChangeFinishDate = 'change_finish_date',
  ChangePlannedSeconds = 'change_planned_seconds',
  ChangeStep = 'change_step',
  ChangeTitle = 'change_title',
  ChangeType = 'change_type',
  CreateNote = 'create_note',
  CreateSubtask = 'create_subtask',
  CreateTask = 'create_task',
  DeleteFeedback = 'delete_feedback',
  DeleteFile = 'delete_file',
  DeleteNote = 'delete_note',
  DeleteSubtask = 'delete_subtask',
  DetachUser = 'detach_user',
  Reject = 'reject',
  UpdateFeedback = 'update_feedback',
  UpdateFeedbackStatus = 'update_feedback_status',
  UpdateNote = 'update_note',
  UploadFile = 'upload_file'
}

export type TaskEventApproveTask = TaskEvent & {
  __typename?: 'TaskEventApproveTask';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  nps?: Maybe<Scalars['Int']['output']>;
  task: Task;
};

export type TaskEventAttachUser = TaskEvent & {
  __typename?: 'TaskEventAttachUser';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
  user?: Maybe<User>;
};

export type TaskEventBodyChanged = TaskEvent & {
  __typename?: 'TaskEventBodyChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
};

export type TaskEventCellChanged = TaskEvent & {
  __typename?: 'TaskEventCellChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_cell?: Maybe<Cell>;
  new_name?: Maybe<Scalars['String']['output']>;
  old_cell?: Maybe<Cell>;
  old_name?: Maybe<Scalars['String']['output']>;
  task: Task;
};

export type TaskEventDeleteFeedback = TaskEvent & {
  __typename?: 'TaskEventDeleteFeedback';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  from_user?: Maybe<User>;
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
};

export type TaskEventDetachUser = TaskEvent & {
  __typename?: 'TaskEventDetachUser';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
  user?: Maybe<User>;
};

export type TaskEventExecutionDateChanged = TaskEvent & {
  __typename?: 'TaskEventExecutionDateChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_execution_date?: Maybe<Scalars['Date']['output']>;
  old_execution_date?: Maybe<Scalars['Date']['output']>;
  task: Task;
};

export type TaskEventFileDeleted = TaskEvent & {
  __typename?: 'TaskEventFileDeleted';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
};

export type TaskEventFileUploaded = TaskEvent & {
  __typename?: 'TaskEventFileUploaded';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
};

export type TaskEventFinishDateChanged = TaskEvent & {
  __typename?: 'TaskEventFinishDateChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_finish_date?: Maybe<Scalars['Date']['output']>;
  old_finish_date?: Maybe<Scalars['Date']['output']>;
  task: Task;
};

export type TaskEventNoteCreated = TaskEvent & {
  __typename?: 'TaskEventNoteCreated';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  note?: Maybe<Note>;
  task: Task;
};

export type TaskEventNoteDeleted = TaskEvent & {
  __typename?: 'TaskEventNoteDeleted';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
};

export type TaskEventNoteUpdated = TaskEvent & {
  __typename?: 'TaskEventNoteUpdated';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  note?: Maybe<Note>;
  task: Task;
};

export type TaskEventPaginator = Paginator & {
  __typename?: 'TaskEventPaginator';
  count: Scalars['Int']['output'];
  data: Array<TaskEvent>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TaskEventPlannedSecondsChanged = TaskEvent & {
  __typename?: 'TaskEventPlannedSecondsChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_planned_seconds?: Maybe<Scalars['Int']['output']>;
  old_planned_seconds?: Maybe<Scalars['Int']['output']>;
  task: Task;
};

export type TaskEventRejectTask = TaskEvent & {
  __typename?: 'TaskEventRejectTask';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  nps?: Maybe<Scalars['Int']['output']>;
  task: Task;
};

export type TaskEventStepChanged = TaskEvent & {
  __typename?: 'TaskEventStepChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_step: TaskStep;
  old_step?: Maybe<TaskStep>;
  task: Task;
};

export type TaskEventSubTaskCreated = TaskEvent & {
  __typename?: 'TaskEventSubTaskCreated';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  subtask?: Maybe<Task>;
  task: Task;
  title: Scalars['String']['output'];
};

export type TaskEventSubTaskDeleted = TaskEvent & {
  __typename?: 'TaskEventSubTaskDeleted';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
  title: Scalars['String']['output'];
};

export type TaskEventTaskCreated = TaskEvent & {
  __typename?: 'TaskEventTaskCreated';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
};

export type TaskEventTitleChanged = TaskEvent & {
  __typename?: 'TaskEventTitleChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  new_title: Scalars['String']['output'];
  old_title?: Maybe<Scalars['String']['output']>;
  task: Task;
};

export type TaskEventTypeChanged = TaskEvent & {
  __typename?: 'TaskEventTypeChanged';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  task: Task;
  type: TaskType;
};

export type TaskEventUpdateFeedback = TaskEvent & {
  __typename?: 'TaskEventUpdateFeedback';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  feedback?: Maybe<Feedback>;
  from_user?: Maybe<User>;
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  task: Task;
};

export type TaskEventUpdateFeedbackStatus = TaskEvent & {
  __typename?: 'TaskEventUpdateFeedbackStatus';
  action: TaskEventAction;
  created_at: Scalars['DateTime']['output'];
  feedback?: Maybe<Feedback>;
  from_user?: Maybe<User>;
  id: Scalars['ID']['output'];
  initiator?: Maybe<User>;
  name: Scalars['String']['output'];
  status: FeedbackStatus;
  task: Task;
};

export type TaskFeedbackCreatedNotification = Notification & {
  __typename?: 'TaskFeedbackCreatedNotification';
  feedback: Feedback;
  id: Scalars['ID']['output'];
  initiator: User;
  notified_at: Scalars['DateTime']['output'];
  recipient: User;
  task: Task;
  type: NotificationType;
  viewed: Scalars['Boolean']['output'];
  viewed_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskFilter = {
  cells?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeAllExceptTasksWithPendingFeedbacks?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSubtasks?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<DatePeriod>;
  periodSelector?: InputMaybe<PeriodSelector>;
  steps?: InputMaybe<Array<TaskStep>>;
  templates?: InputMaybe<Array<Scalars['ID']['input']>>;
  types?: InputMaybe<Array<TaskType>>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TaskNoteCreatedNotification = Notification & {
  __typename?: 'TaskNoteCreatedNotification';
  id: Scalars['ID']['output'];
  initiator: User;
  note: Note;
  notified_at: Scalars['DateTime']['output'];
  recipient: User;
  task: Task;
  type: NotificationType;
  viewed: Scalars['Boolean']['output'];
  viewed_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskPaginator = Paginator & {
  __typename?: 'TaskPaginator';
  count: Scalars['Int']['output'];
  data: Array<Task>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum TaskStep {
  Approval = 'approval',
  Check = 'check',
  Done = 'done',
  Execution = 'execution',
  Finish = 'finish'
}

export type TaskStepChangedNotification = Notification & {
  __typename?: 'TaskStepChangedNotification';
  id: Scalars['ID']['output'];
  initiator: User;
  notified_at: Scalars['DateTime']['output'];
  recipient: User;
  step: TaskStep;
  task: Task;
  type: NotificationType;
  viewed: Scalars['Boolean']['output'];
  viewed_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum TaskType {
  Assignment = 'assignment',
  Default = 'default'
}

export type TasksChecking = {
  __typename?: 'TasksChecking';
  approval: TasksCheckingInfo;
  check: TasksCheckingInfo;
  done: TasksCheckingInfo;
  execution: TasksCheckingInfo;
  finish: TasksCheckingInfo;
  timers: Scalars['Int']['output'];
};

export type TasksCheckingInfo = {
  __typename?: 'TasksCheckingInfo';
  delayed: Scalars['Int']['output'];
  done: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
};

export type TasksStats = {
  __typename?: 'TasksStats';
  steps: Array<StepStats>;
  templates: Array<TemplateStats>;
};

export type Template = {
  __typename?: 'Template';
  account?: Maybe<Account>;
  id: Scalars['ID']['output'];
  items: TemplateItemPaginator;
  pos?: Maybe<Scalars['Int']['output']>;
  realm: Realm;
  title: Scalars['String']['output'];
};


export type TemplateItemsArgs = {
  filter?: InputMaybe<TemplateItemFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type TemplateFilter = {
  hideAccountTemplates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateItem = {
  __typename?: 'TemplateItem';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parent?: Maybe<TemplateItem>;
  /** Tempo planejado para execução da atividade */
  planned_seconds?: Maybe<Scalars['Int']['output']>;
  pos?: Maybe<Scalars['Int']['output']>;
  subitems: TemplateItemPaginator;
  template: Template;
  timers_report?: Maybe<TemplateItemTimersReport>;
  title: Scalars['String']['output'];
  type: TaskType;
};


export type TemplateItemSubitemsArgs = {
  filter?: InputMaybe<TemplateItemFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type TemplateItemTimers_ReportArgs = {
  period: DatePeriod;
};

export type TemplateItemFilter = {
  excludeSubitems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateItemPaginator = Paginator & {
  __typename?: 'TemplateItemPaginator';
  count: Scalars['Int']['output'];
  data: Array<TemplateItem>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Informações sobre timers de atividades deste template */
export type TemplateItemTimersReport = {
  __typename?: 'TemplateItemTimersReport';
  average_seconds: Scalars['Float']['output'];
  max_seconds: Scalars['Int']['output'];
  min_seconds: Scalars['Int']['output'];
  tasks: Scalars['Int']['output'];
  total_seconds: Scalars['Int']['output'];
};

export type TemplatePaginator = Paginator & {
  __typename?: 'TemplatePaginator';
  count: Scalars['Int']['output'];
  data: Array<Template>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TemplateStats = {
  __typename?: 'TemplateStats';
  count: Scalars['Int']['output'];
  template: Template;
};

export type ThirdPartyAccount = {
  __typename?: 'ThirdPartyAccount';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ThirdPartyAccountAttachType {
  Facebook = 'facebook',
  Google = 'google'
}

export type Timer = {
  __typename?: 'Timer';
  account: Account;
  account_id: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  finish_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  start_date: Scalars['DateTime']['output'];
  task?: Maybe<Task>;
  task_id?: Maybe<Scalars['ID']['output']>;
  total_seconds: Scalars['Int']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type TimerFilter = {
  accounts?: InputMaybe<Array<Scalars['ID']['input']>>;
  cells?: InputMaybe<Array<Scalars['ID']['input']>>;
  period?: InputMaybe<DatePeriod>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TimerPaginator = Paginator & {
  __typename?: 'TimerPaginator';
  count: Scalars['Int']['output'];
  data: Array<Timer>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accounts: AccountPaginator;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  cells: CellPaginator;
  devices: DevicePaginator;
  email: Scalars['String']['output'];
  feedbacks: FeedbackPaginator;
  id: Scalars['ID']['output'];
  myUnresolvedFeedbacks: FeedbackPaginator;
  name: Scalars['String']['output'];
  realm?: Maybe<Realm>;
  roles: Array<UserRole>;
  stats: UserStats;
  status: UserStatus;
  summary: UserSummary;
  tasks: TaskPaginator;
  timer?: Maybe<Timer>;
  timers: TimerPaginator;
};


export type UserAccountsArgs = {
  pagination?: InputMaybe<Pagination>;
  status?: InputMaybe<Array<AccountStatus>>;
};


export type UserCellsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserDevicesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserFeedbacksArgs = {
  filter?: InputMaybe<FeedbackFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type UserMyUnresolvedFeedbacksArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserSummaryArgs = {
  period?: InputMaybe<DatePeriod>;
};


export type UserTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type UserTimersArgs = {
  filter?: InputMaybe<TimerFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type UserFilter = {
  roles?: InputMaybe<Array<UserRole>>;
  status?: InputMaybe<Array<UserStatus>>;
};

export type UserPaginator = Paginator & {
  __typename?: 'UserPaginator';
  count: Scalars['Int']['output'];
  data: Array<User>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum UserRole {
  Admin = 'admin',
  Creator = 'creator',
  Customer = 'customer',
  CustomerSuccess = 'customer_success',
  MediaTester = 'media_tester'
}

export type UserStats = {
  __typename?: 'UserStats';
  tasks: TasksStats;
};


export type UserStatsTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
};

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type UserSummary = {
  __typename?: 'UserSummary';
  planned_seconds: Scalars['Int']['output'];
  planned_seconds_used: Scalars['Int']['output'];
  tasks_delayed: Scalars['Int']['output'];
  tasks_done: Scalars['Int']['output'];
  tasks_total: Scalars['Int']['output'];
  total_seconds_used: Scalars['Int']['output'];
};

export type WhatsAppLink = Link & {
  __typename?: 'WhatsAppLink';
  account: Account;
  account_id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  dest: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ml_group_id?: Maybe<Scalars['String']['output']>;
  ml_sync?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
  realm: Realm;
  realm_id: Scalars['ID']['output'];
  subdomain: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: LinkType;
  url: Scalars['String']['output'];
};

export type SelectedAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SelectedAccountQuery = { __typename?: 'Query', account: { __typename?: 'Account', id: string, name: string, logoUrl?: string | null } };

export type SessionQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionQuery = { __typename?: 'Query', session: { __typename?: 'Session', token: string, user: { __typename?: 'User', id: string, name: string, email: string, avatarUrl?: string | null, accounts: { __typename?: 'AccountPaginator', data: Array<{ __typename?: 'Account', id: string }> } } } };

export type BadgeCountersQueryVariables = Exact<{
  account: Scalars['ID']['input'];
}>;


export type BadgeCountersQuery = { __typename?: 'Query', account: { __typename?: 'Account', stats: { __typename?: 'AccountStats', tasks: { __typename?: 'TasksStats', steps: Array<{ __typename?: 'StepStats', step: TaskStep, count: number }> }, leads: { __typename?: 'LeadsStats', classifications: Array<{ __typename?: 'LeadClassificationStats', classification: LeadClassification, count: number }> } } } };

export type UpdateDeviceInfoMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDeviceInfoMutation = { __typename?: 'Mutation', updateDeviceInfo: { __typename?: 'User', id: string } };

export type DeleteDeviceInfoMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;


export type DeleteDeviceInfoMutation = { __typename?: 'Mutation', deleteDeviceInfo: { __typename?: 'User', id: string } };

export type AccountsListQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsListQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, accounts: { __typename?: 'AccountPaginator', data: Array<{ __typename?: 'Account', id: string, name: string, logoUrl?: string | null, stats: { __typename?: 'AccountStats', tasks: { __typename?: 'TasksStats', steps: Array<{ __typename?: 'StepStats', step: TaskStep, count: number }> } } }> } } };

export type ApprovalTasksQueryVariables = Exact<{
  account: Scalars['ID']['input'];
  begin: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;


export type ApprovalTasksQuery = { __typename?: 'Query', approval: { __typename?: 'Account', tasks: { __typename?: 'TaskPaginator', data: Array<{ __typename?: 'AssignmentTask', id: string, title: string, finish_date?: any | null } | { __typename?: 'DefaultTask', id: string, title: string, finish_date?: any | null }> } }, next: { __typename?: 'Account', tasks: { __typename?: 'TaskPaginator', data: Array<{ __typename?: 'AssignmentTask', id: string, title: string, finish_date?: any | null } | { __typename?: 'DefaultTask', id: string, title: string, finish_date?: any | null }> } } };

export type ApproveTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApproveTaskMutation = { __typename?: 'Mutation', approveTask: { __typename?: 'AssignmentTask', id: string, step: TaskStep } | { __typename?: 'DefaultTask', id: string, step: TaskStep } };

export type RejectTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  feedback: Scalars['String']['input'];
}>;


export type RejectTaskMutation = { __typename?: 'Mutation', rejectTask: { __typename?: 'AssignmentTask', id: string, step: TaskStep } | { __typename?: 'DefaultTask', id: string, step: TaskStep } };

export type UserFeedbackMutationVariables = Exact<{
  payload: Scalars['String']['input'];
}>;


export type UserFeedbackMutation = { __typename?: 'Mutation', userFeedback: boolean };

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string, email: string } };

export type LeadQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  leadId: Scalars['ID']['input'];
}>;


export type LeadQuery = { __typename?: 'Query', lead: { __typename?: 'Lead', id: string, source: LeadSource, name?: string | null, emails: Array<string>, phones: Array<string>, notes?: string | null, classification: LeadClassification, subscribed_at: any, fields: Array<{ __typename?: 'LeadField', key: string, value: string }> }, account: { __typename?: 'Account', id: string, mailerlite_fields?: Array<{ __typename?: 'MailerliteField', key: string, name: string }> | null } };

export type UpdateLeadNotesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
}>;


export type UpdateLeadNotesMutation = { __typename?: 'Mutation', updateLead: { __typename?: 'Lead', id: string, notes?: string | null } };

export type UpdateLeadClassificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  classification: LeadClassification;
}>;


export type UpdateLeadClassificationMutation = { __typename?: 'Mutation', updateLead: { __typename?: 'Lead', id: string, classification: LeadClassification } };

export type DeleteLeadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteLeadMutation = { __typename?: 'Mutation', deleteLead: boolean };

export type AccountLeadsQueryVariables = Exact<{
  account: Scalars['ID']['input'];
  begin: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;


export type AccountLeadsQuery = { __typename?: 'Query', account: { __typename?: 'Account', leads: { __typename?: 'LeadPaginator', data: Array<{ __typename?: 'Lead', id: string, name?: string | null, emails: Array<string>, source: LeadSource, classification: LeadClassification, subscribed_at: any }> } } };

export type FilterAccountLeadsQueryVariables = Exact<{
  account: Scalars['ID']['input'];
  begin?: InputMaybe<Scalars['Date']['input']>;
  end?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<Array<LeadSource> | LeadSource>;
}>;


export type FilterAccountLeadsQuery = { __typename?: 'Query', account: { __typename?: 'Account', leads: { __typename?: 'LeadPaginator', data: Array<{ __typename?: 'Lead', id: string, name?: string | null, emails: Array<string>, source: LeadSource, classification: LeadClassification, subscribed_at: any }> } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: string };

export type TaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TaskQuery = { __typename?: 'Query', task: { __typename?: 'AssignmentTask', id: string, step: TaskStep, title: string, body?: string | null, files: { __typename?: 'FilePaginator', data: Array<{ __typename?: 'File', id: string, name: string, thumbnailUrl: string, info: { __typename?: 'FileInfo', mimetype: string, url?: string | null }, opt_info?: { __typename?: 'FileInfo', mimetype: string, url?: string | null } | null }> } } | { __typename?: 'DefaultTask', id: string, step: TaskStep, title: string, body?: string | null, files: { __typename?: 'FilePaginator', data: Array<{ __typename?: 'File', id: string, name: string, thumbnailUrl: string, info: { __typename?: 'FileInfo', mimetype: string, url?: string | null }, opt_info?: { __typename?: 'FileInfo', mimetype: string, url?: string | null } | null }> } } };

export type AccountTasksQueryVariables = Exact<{
  account: Scalars['ID']['input'];
  begin: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;


export type AccountTasksQuery = { __typename?: 'Query', account: { __typename?: 'Account', tasks: { __typename?: 'TaskPaginator', data: Array<{ __typename?: 'AssignmentTask', id: string, step: TaskStep, title: string, finish_date?: any | null } | { __typename?: 'DefaultTask', id: string, step: TaskStep, title: string, finish_date?: any | null }> } } };


export const SelectedAccountDocument = gql`
    query SelectedAccount($id: ID!) {
  account(id: $id) {
    id
    name
    logoUrl
  }
}
    `;

/**
 * __useSelectedAccountQuery__
 *
 * To run a query within a React component, call `useSelectedAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectedAccountQuery(baseOptions: Apollo.QueryHookOptions<SelectedAccountQuery, SelectedAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectedAccountQuery, SelectedAccountQueryVariables>(SelectedAccountDocument, options);
      }
export function useSelectedAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectedAccountQuery, SelectedAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectedAccountQuery, SelectedAccountQueryVariables>(SelectedAccountDocument, options);
        }
export type SelectedAccountQueryHookResult = ReturnType<typeof useSelectedAccountQuery>;
export type SelectedAccountLazyQueryHookResult = ReturnType<typeof useSelectedAccountLazyQuery>;
export type SelectedAccountQueryResult = Apollo.QueryResult<SelectedAccountQuery, SelectedAccountQueryVariables>;
export const SessionDocument = gql`
    query Session {
  session {
    token
    user {
      id
      name
      email
      avatarUrl
      accounts(pagination: {limit: -1}) {
        data {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const BadgeCountersDocument = gql`
    query BadgeCounters($account: ID!) {
  account(id: $account) {
    stats {
      tasks {
        steps {
          step
          count
        }
      }
      leads {
        classifications {
          classification
          count
        }
      }
    }
  }
}
    `;

/**
 * __useBadgeCountersQuery__
 *
 * To run a query within a React component, call `useBadgeCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeCountersQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useBadgeCountersQuery(baseOptions: Apollo.QueryHookOptions<BadgeCountersQuery, BadgeCountersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BadgeCountersQuery, BadgeCountersQueryVariables>(BadgeCountersDocument, options);
      }
export function useBadgeCountersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BadgeCountersQuery, BadgeCountersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BadgeCountersQuery, BadgeCountersQueryVariables>(BadgeCountersDocument, options);
        }
export type BadgeCountersQueryHookResult = ReturnType<typeof useBadgeCountersQuery>;
export type BadgeCountersLazyQueryHookResult = ReturnType<typeof useBadgeCountersLazyQuery>;
export type BadgeCountersQueryResult = Apollo.QueryResult<BadgeCountersQuery, BadgeCountersQueryVariables>;
export const UpdateDeviceInfoDocument = gql`
    mutation UpdateDeviceInfo($deviceId: String!, $expoPushToken: String) {
  updateDeviceInfo(device_id: $deviceId, expo_push_token: $expoPushToken) {
    id
  }
}
    `;
export type UpdateDeviceInfoMutationFn = Apollo.MutationFunction<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>;

/**
 * __useUpdateDeviceInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceInfoMutation, { data, loading, error }] = useUpdateDeviceInfoMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      expoPushToken: // value for 'expoPushToken'
 *   },
 * });
 */
export function useUpdateDeviceInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>(UpdateDeviceInfoDocument, options);
      }
export type UpdateDeviceInfoMutationHookResult = ReturnType<typeof useUpdateDeviceInfoMutation>;
export type UpdateDeviceInfoMutationResult = Apollo.MutationResult<UpdateDeviceInfoMutation>;
export type UpdateDeviceInfoMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>;
export const DeleteDeviceInfoDocument = gql`
    mutation DeleteDeviceInfo($deviceId: String!) {
  deleteDeviceInfo(device_id: $deviceId) {
    id
  }
}
    `;
export type DeleteDeviceInfoMutationFn = Apollo.MutationFunction<DeleteDeviceInfoMutation, DeleteDeviceInfoMutationVariables>;

/**
 * __useDeleteDeviceInfoMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceInfoMutation, { data, loading, error }] = useDeleteDeviceInfoMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeleteDeviceInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceInfoMutation, DeleteDeviceInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceInfoMutation, DeleteDeviceInfoMutationVariables>(DeleteDeviceInfoDocument, options);
      }
export type DeleteDeviceInfoMutationHookResult = ReturnType<typeof useDeleteDeviceInfoMutation>;
export type DeleteDeviceInfoMutationResult = Apollo.MutationResult<DeleteDeviceInfoMutation>;
export type DeleteDeviceInfoMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceInfoMutation, DeleteDeviceInfoMutationVariables>;
export const AccountsListDocument = gql`
    query AccountsList {
  me {
    id
    accounts(status: active, pagination: {limit: -1}) {
      data {
        id
        name
        logoUrl
        stats {
          tasks {
            steps {
              step
              count
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountsListQuery__
 *
 * To run a query within a React component, call `useAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsListQuery(baseOptions?: Apollo.QueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, options);
      }
export function useAccountsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, options);
        }
export type AccountsListQueryHookResult = ReturnType<typeof useAccountsListQuery>;
export type AccountsListLazyQueryHookResult = ReturnType<typeof useAccountsListLazyQuery>;
export type AccountsListQueryResult = Apollo.QueryResult<AccountsListQuery, AccountsListQueryVariables>;
export const ApprovalTasksDocument = gql`
    query ApprovalTasks($account: ID!, $begin: Date!, $end: Date!) {
  approval: account(id: $account) {
    tasks(filter: {types: default, steps: approval}, pagination: {limit: -1}) {
      data {
        id
        title
        finish_date
      }
    }
  }
  next: account(id: $account) {
    tasks(
      filter: {types: default, steps: [execution, check], periodSelector: finish_date, period: {begin: $begin, end: $end}}
      pagination: {limit: -1}
    ) {
      data {
        id
        title
        finish_date
      }
    }
  }
}
    `;

/**
 * __useApprovalTasksQuery__
 *
 * To run a query within a React component, call `useApprovalTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalTasksQuery({
 *   variables: {
 *      account: // value for 'account'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useApprovalTasksQuery(baseOptions: Apollo.QueryHookOptions<ApprovalTasksQuery, ApprovalTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalTasksQuery, ApprovalTasksQueryVariables>(ApprovalTasksDocument, options);
      }
export function useApprovalTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalTasksQuery, ApprovalTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalTasksQuery, ApprovalTasksQueryVariables>(ApprovalTasksDocument, options);
        }
export type ApprovalTasksQueryHookResult = ReturnType<typeof useApprovalTasksQuery>;
export type ApprovalTasksLazyQueryHookResult = ReturnType<typeof useApprovalTasksLazyQuery>;
export type ApprovalTasksQueryResult = Apollo.QueryResult<ApprovalTasksQuery, ApprovalTasksQueryVariables>;
export const ApproveTaskDocument = gql`
    mutation ApproveTask($id: ID!, $feedback: String) {
  approveTask(id: $id, feedback: $feedback) {
    id
    step
  }
}
    `;
export type ApproveTaskMutationFn = Apollo.MutationFunction<ApproveTaskMutation, ApproveTaskMutationVariables>;

/**
 * __useApproveTaskMutation__
 *
 * To run a mutation, you first call `useApproveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTaskMutation, { data, loading, error }] = useApproveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useApproveTaskMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTaskMutation, ApproveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveTaskMutation, ApproveTaskMutationVariables>(ApproveTaskDocument, options);
      }
export type ApproveTaskMutationHookResult = ReturnType<typeof useApproveTaskMutation>;
export type ApproveTaskMutationResult = Apollo.MutationResult<ApproveTaskMutation>;
export type ApproveTaskMutationOptions = Apollo.BaseMutationOptions<ApproveTaskMutation, ApproveTaskMutationVariables>;
export const RejectTaskDocument = gql`
    mutation RejectTask($id: ID!, $feedback: String!) {
  rejectTask(id: $id, feedback: $feedback) {
    id
    step
  }
}
    `;
export type RejectTaskMutationFn = Apollo.MutationFunction<RejectTaskMutation, RejectTaskMutationVariables>;

/**
 * __useRejectTaskMutation__
 *
 * To run a mutation, you first call `useRejectTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTaskMutation, { data, loading, error }] = useRejectTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useRejectTaskMutation(baseOptions?: Apollo.MutationHookOptions<RejectTaskMutation, RejectTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTaskMutation, RejectTaskMutationVariables>(RejectTaskDocument, options);
      }
export type RejectTaskMutationHookResult = ReturnType<typeof useRejectTaskMutation>;
export type RejectTaskMutationResult = Apollo.MutationResult<RejectTaskMutation>;
export type RejectTaskMutationOptions = Apollo.BaseMutationOptions<RejectTaskMutation, RejectTaskMutationVariables>;
export const UserFeedbackDocument = gql`
    mutation UserFeedback($payload: String!) {
  userFeedback(payload: $payload)
}
    `;
export type UserFeedbackMutationFn = Apollo.MutationFunction<UserFeedbackMutation, UserFeedbackMutationVariables>;

/**
 * __useUserFeedbackMutation__
 *
 * To run a mutation, you first call `useUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFeedbackMutation, { data, loading, error }] = useUserFeedbackMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<UserFeedbackMutation, UserFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFeedbackMutation, UserFeedbackMutationVariables>(UserFeedbackDocument, options);
      }
export type UserFeedbackMutationHookResult = ReturnType<typeof useUserFeedbackMutation>;
export type UserFeedbackMutationResult = Apollo.MutationResult<UserFeedbackMutation>;
export type UserFeedbackMutationOptions = Apollo.BaseMutationOptions<UserFeedbackMutation, UserFeedbackMutationVariables>;
export const TestDocument = gql`
    query test {
  me {
    id
    name
    email
  }
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<TestQuery, TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestQuery, TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<TestQuery, TestQueryVariables>;
export const LeadDocument = gql`
    query Lead($accountId: ID!, $leadId: ID!) {
  lead(id: $leadId) {
    id
    source
    name
    emails
    phones
    fields {
      key
      value
    }
    notes
    classification
    subscribed_at
  }
  account(id: $accountId) {
    id
    mailerlite_fields {
      key
      name
    }
  }
}
    `;

/**
 * __useLeadQuery__
 *
 * To run a query within a React component, call `useLeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useLeadQuery(baseOptions: Apollo.QueryHookOptions<LeadQuery, LeadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadQuery, LeadQueryVariables>(LeadDocument, options);
      }
export function useLeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadQuery, LeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadQuery, LeadQueryVariables>(LeadDocument, options);
        }
export type LeadQueryHookResult = ReturnType<typeof useLeadQuery>;
export type LeadLazyQueryHookResult = ReturnType<typeof useLeadLazyQuery>;
export type LeadQueryResult = Apollo.QueryResult<LeadQuery, LeadQueryVariables>;
export const UpdateLeadNotesDocument = gql`
    mutation UpdateLeadNotes($id: ID!, $notes: String!) {
  updateLead(id: $id, notes: $notes) {
    id
    notes
  }
}
    `;
export type UpdateLeadNotesMutationFn = Apollo.MutationFunction<UpdateLeadNotesMutation, UpdateLeadNotesMutationVariables>;

/**
 * __useUpdateLeadNotesMutation__
 *
 * To run a mutation, you first call `useUpdateLeadNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadNotesMutation, { data, loading, error }] = useUpdateLeadNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateLeadNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadNotesMutation, UpdateLeadNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadNotesMutation, UpdateLeadNotesMutationVariables>(UpdateLeadNotesDocument, options);
      }
export type UpdateLeadNotesMutationHookResult = ReturnType<typeof useUpdateLeadNotesMutation>;
export type UpdateLeadNotesMutationResult = Apollo.MutationResult<UpdateLeadNotesMutation>;
export type UpdateLeadNotesMutationOptions = Apollo.BaseMutationOptions<UpdateLeadNotesMutation, UpdateLeadNotesMutationVariables>;
export const UpdateLeadClassificationDocument = gql`
    mutation UpdateLeadClassification($id: ID!, $classification: LeadClassification!) {
  updateLead(id: $id, classification: $classification) {
    id
    classification
  }
}
    `;
export type UpdateLeadClassificationMutationFn = Apollo.MutationFunction<UpdateLeadClassificationMutation, UpdateLeadClassificationMutationVariables>;

/**
 * __useUpdateLeadClassificationMutation__
 *
 * To run a mutation, you first call `useUpdateLeadClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadClassificationMutation, { data, loading, error }] = useUpdateLeadClassificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      classification: // value for 'classification'
 *   },
 * });
 */
export function useUpdateLeadClassificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadClassificationMutation, UpdateLeadClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadClassificationMutation, UpdateLeadClassificationMutationVariables>(UpdateLeadClassificationDocument, options);
      }
export type UpdateLeadClassificationMutationHookResult = ReturnType<typeof useUpdateLeadClassificationMutation>;
export type UpdateLeadClassificationMutationResult = Apollo.MutationResult<UpdateLeadClassificationMutation>;
export type UpdateLeadClassificationMutationOptions = Apollo.BaseMutationOptions<UpdateLeadClassificationMutation, UpdateLeadClassificationMutationVariables>;
export const DeleteLeadDocument = gql`
    mutation DeleteLead($id: ID!) {
  deleteLead(id: $id)
}
    `;
export type DeleteLeadMutationFn = Apollo.MutationFunction<DeleteLeadMutation, DeleteLeadMutationVariables>;

/**
 * __useDeleteLeadMutation__
 *
 * To run a mutation, you first call `useDeleteLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadMutation, { data, loading, error }] = useDeleteLeadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLeadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeadMutation, DeleteLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeadMutation, DeleteLeadMutationVariables>(DeleteLeadDocument, options);
      }
export type DeleteLeadMutationHookResult = ReturnType<typeof useDeleteLeadMutation>;
export type DeleteLeadMutationResult = Apollo.MutationResult<DeleteLeadMutation>;
export type DeleteLeadMutationOptions = Apollo.BaseMutationOptions<DeleteLeadMutation, DeleteLeadMutationVariables>;
export const AccountLeadsDocument = gql`
    query AccountLeads($account: ID!, $begin: Date!, $end: Date!) {
  account(id: $account) {
    leads(
      filter: {subscribed_at: {begin: $begin, end: $end}}
      pagination: {limit: -1}
    ) {
      data {
        id
        name
        emails
        source
        classification
        subscribed_at
      }
    }
  }
}
    `;

/**
 * __useAccountLeadsQuery__
 *
 * To run a query within a React component, call `useAccountLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLeadsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAccountLeadsQuery(baseOptions: Apollo.QueryHookOptions<AccountLeadsQuery, AccountLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountLeadsQuery, AccountLeadsQueryVariables>(AccountLeadsDocument, options);
      }
export function useAccountLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountLeadsQuery, AccountLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountLeadsQuery, AccountLeadsQueryVariables>(AccountLeadsDocument, options);
        }
export type AccountLeadsQueryHookResult = ReturnType<typeof useAccountLeadsQuery>;
export type AccountLeadsLazyQueryHookResult = ReturnType<typeof useAccountLeadsLazyQuery>;
export type AccountLeadsQueryResult = Apollo.QueryResult<AccountLeadsQuery, AccountLeadsQueryVariables>;
export const FilterAccountLeadsDocument = gql`
    query FilterAccountLeads($account: ID!, $begin: Date, $end: Date, $name: String, $email: String, $phone: String, $sources: [LeadSource!]) {
  account(id: $account) {
    leads(
      filter: {subscribed_at: {begin: $begin, end: $end}, name: $name, email: $email, phone: $phone, sources: $sources}
      pagination: {limit: -1}
    ) {
      data {
        id
        name
        emails
        source
        classification
        subscribed_at
      }
    }
  }
}
    `;

/**
 * __useFilterAccountLeadsQuery__
 *
 * To run a query within a React component, call `useFilterAccountLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterAccountLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterAccountLeadsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      sources: // value for 'sources'
 *   },
 * });
 */
export function useFilterAccountLeadsQuery(baseOptions: Apollo.QueryHookOptions<FilterAccountLeadsQuery, FilterAccountLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterAccountLeadsQuery, FilterAccountLeadsQueryVariables>(FilterAccountLeadsDocument, options);
      }
export function useFilterAccountLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterAccountLeadsQuery, FilterAccountLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterAccountLeadsQuery, FilterAccountLeadsQueryVariables>(FilterAccountLeadsDocument, options);
        }
export type FilterAccountLeadsQueryHookResult = ReturnType<typeof useFilterAccountLeadsQuery>;
export type FilterAccountLeadsLazyQueryHookResult = ReturnType<typeof useFilterAccountLeadsLazyQuery>;
export type FilterAccountLeadsQueryResult = Apollo.QueryResult<FilterAccountLeadsQuery, FilterAccountLeadsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const TaskDocument = gql`
    query Task($id: ID!) {
  task(id: $id) {
    id
    step
    title
    body
    files(pagination: {limit: -1}) {
      data {
        id
        name
        thumbnailUrl
        info {
          mimetype
          url
        }
        opt_info {
          mimetype
          url
        }
      }
    }
  }
}
    `;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const AccountTasksDocument = gql`
    query AccountTasks($account: ID!, $begin: Date!, $end: Date!) {
  account(id: $account) {
    tasks(
      filter: {types: default, periodSelector: finish_date, period: {begin: $begin, end: $end}}
      pagination: {limit: -1}
    ) {
      data {
        id
        step
        title
        finish_date
      }
    }
  }
}
    `;

/**
 * __useAccountTasksQuery__
 *
 * To run a query within a React component, call `useAccountTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTasksQuery({
 *   variables: {
 *      account: // value for 'account'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAccountTasksQuery(baseOptions: Apollo.QueryHookOptions<AccountTasksQuery, AccountTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTasksQuery, AccountTasksQueryVariables>(AccountTasksDocument, options);
      }
export function useAccountTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTasksQuery, AccountTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTasksQuery, AccountTasksQueryVariables>(AccountTasksDocument, options);
        }
export type AccountTasksQueryHookResult = ReturnType<typeof useAccountTasksQuery>;
export type AccountTasksLazyQueryHookResult = ReturnType<typeof useAccountTasksLazyQuery>;
export type AccountTasksQueryResult = Apollo.QueryResult<AccountTasksQuery, AccountTasksQueryVariables>;