import React from 'react';
import { Dimensions, Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Carousel from 'react-native-reanimated-carousel';
import PaginationDot from 'react-native-animated-pagination-dot';

import styled, { useTheme } from 'styled-components/native';
import { AntDesign } from '@expo/vector-icons';

import Touchable from '$components/Navigation/Touchable';
import Video from '$components/Video';

import { goBack } from '$navigation';

interface Props {
  route: any;
}

const VideosViewer: React.FC<Props> = ({ route }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const dim = Dimensions.get('window');

  const carouselRef = React.useRef(null);
  const [active, setActive] = React.useState(route.params.index ?? 0);

  const handleSnapToItem = React.useCallback((index: number) => {
    setActive(index);
  }, []);

  const renderItem = React.useCallback(
    ({ index, item }: { index: number; item: string }) => {
      if (active !== index) return <View style={{ flex: 1 }} />;
      return <Video url={item} />;
    },
    [active],
  );

  return (
    <Root style={{ paddingBottom: 20 + insets.bottom }}>
      {Platform.OS === 'android' && (
        <CloseButtonViewAndroid>
          <Touchable onPress={() => goBack()}>
            <AntDesign name="closecircle" color="#dddddd" size={22} />
          </Touchable>
        </CloseButtonViewAndroid>
      )}

      {Platform.OS === 'ios' && (
        <CloseButtonViewIos>
          <Touchable onPress={() => goBack()}>
            <CloseButtonIosRoot>
              <AntDesign name="close" color="#ffffffc0" size={19} />
            </CloseButtonIosRoot>
          </Touchable>
        </CloseButtonViewIos>
      )}

      {Platform.OS === 'web' && (
        <CloseButtonViewWeb>
          <Touchable onPress={() => goBack()}>
            <AntDesign name="closecircle" color="#dddddd" size={22} />
          </Touchable>
        </CloseButtonViewWeb>
      )}

      <Carousel
        width={dim.width}
        height={dim.height - (190 + insets.bottom)}
        data={route.params.videos}
        scrollAnimationDuration={300}
        onSnapToItem={handleSnapToItem}
        renderItem={renderItem}
      />

      <View style={{ alignItems: 'center' }}>
        <PaginationDot
          sizeRatio={1.5}
          activeDotColor={theme.primary.hex()}
          curPage={active}
          maxPage={route.params.videos.length}
        />
      </View>
    </Root>
  );
};

const Root = styled.View`
  flex: 1;
  background-color: #000;
`;

const CloseButtonViewWeb = styled.View`
  position: absolute;
  z-index: 9999;
  top: 16px;
  right: 16px;
`;

const CloseButtonViewAndroid = styled.View`
  position: absolute;
  z-index: 9999;
  right: 16px;
  top: 16px;
`;

const CloseButtonViewIos = styled.View`
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  padding: 6px 0;
`;

const CloseButtonIosRoot = styled.View`
  background-color: #363636aa;
  padding: 6px 14px;
  border-radius: 8px;
`;

export default VideosViewer;
