import React from 'react';
import { Alert as RNAlert, findNodeHandle } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Markdown from 'react-native-marked';

import * as Linking from 'expo-linking';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';

import { AntDesign, FontAwesome } from '@expo/vector-icons';

import Input from '$components/DataEntry/Input';
import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Button from '$components/General/Button';
import Linkify from '$components/General/Linkify';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useApproveTaskMutation, useRejectTaskMutation, useTaskQuery } from '$graphql';
import { goBack, navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';
import { theme } from '$theme';

interface Props {
  route?: any;
}

const Task: React.FC<Props> = ({ route }) => {
  const store = useStore();

  const scrollRef = React.useRef<any>();
  const inputRef = React.useRef<any>();
  const prevSelectedAccountId = React.useRef(store.selectedAccountId);

  const [feedback, setFeedback] = React.useState('');
  const [feedbackHeight, setFeedbackHeight] = React.useState(0);

  const {
    data: task,
    loading,
    error,
  } = useTaskQuery({
    variables: { id: route.params.id },
    fetchPolicy: 'network-only',
  });

  const [approveTask, { loading: approving }] = useApproveTaskMutation();
  const [rejectTask, { loading: rejecting }] = useRejectTaskMutation();

  React.useEffect(() => {
    if (!store.selectedAccountId || store.selectedAccountId === prevSelectedAccountId.current) return;
    navigate('Tasks');
  }, [store.selectedAccountId]);

  function handleEdit() {
    if (!task || !task.task.body) return;
    setFeedback(task.task.body);
    if (inputRef.current) inputRef.current.focus();
  }

  async function handleApproveTask() {
    const { errors } = await approveTask({
      variables: { id: route.params.id, feedback },
    });

    if (errors) {
      return RNAlert.alert(
        'Ocorreu um erro',
        'Não foi possível aprovar a atividade neste momento. Se o problema persistir, feche o aplicativo e tente novamente.',
      );
    }

    store.setBadgeDirty(true);
    goBack();
  }

  async function handleRejectTask() {
    if (!feedback) {
      RNAlert.alert(
        'Insira eu feedback!',
        'Escreva o motivo da rejeição no campo de feedbacks para que possamos resolver o quanto antes.',
      );

      return;
    }

    const { errors } = await rejectTask({ variables: { id: route.params.id, feedback } });

    if (errors) {
      return RNAlert.alert(
        'Ocorreu um erro',
        'Não foi possível rejeitar a atividade neste momento. Se o problema persistir, feche o aplicativo e tente novamente.',
      );
    }

    store.setBadgeDirty(true);
    goBack();
  }

  function handleFocus(e: any) {
    if (!scrollRef.current || !scrollRef.current.scrollToFocusedInput) return;
    scrollRef.current.scrollToFocusedInput(findNodeHandle(e.target));
  }

  const files = task?.task.files.data ?? [];
  const images = files.filter((file) => file.info.mimetype.startsWith('image'));
  const videos = files.filter((file) => file.info.mimetype.startsWith('video'));
  const others = files.filter(
    (file) => !file.info.mimetype.startsWith('image') && !file.info.mimetype.startsWith('video'),
  );

  const isMd = task?.task.body?.startsWith('md\n');

  return (
    <Screen>
      <KeyboardAwareScrollView
        enableOnAndroid
        contentContainerStyle={{ padding: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        extraHeight={200}
        ref={scrollRef}
      >
        {loading && <Loader size="large" />}
        {error && <Alert error message="Ocorreu um erro ao carregar a atividade" description={error.message} />}

        {task && (
          <>
            <Text h2 black paddedBottom>
              {task.task.title}
            </Text>

            {!task.task.body && (
              <Alert
                message="Essa atividade não possui descrição ou texto."
                description={
                  task.task.step !== 'done' && task.task.step !== 'approval'
                    ? 'Algumas atividades só possuem informações e materiais quando a nossa equipe já começou a trabalhar nela.'
                    : undefined
                }
              />
            )}

            {isMd && (
              <Markdown
                value={task.task.body?.replace('md\n', '') ?? ''}
                theme={{
                  colors: {
                    background: 'transparent',
                    border: theme.primary.darken(0.2).desaturate(0.6).toString(),
                    code: theme.primary.darken(0.5).desaturate(0.6).toString(),
                    text: '#fff',
                    link: theme.primary.lighten(0.5).toString(),
                  },
                }}
                flatListProps={{ initialNumToRender: 8, style: { backgroundColor: undefined } }}
              />
            )}

            {!isMd && <Linkify>{task.task.body}</Linkify>}

            {task?.task.step === 'approval' && !!task.task.body && (
              <Button spacedTop={2} padded textProps={{ h3: true }} onPress={handleEdit}>
                <FontAwesome name="pencil" size={16} />
                &nbsp;Editar texto
              </Button>
            )}

            {images.length > 0 && (
              <>
                <Text h3 bold paddedTop={3} paddedBottom={0.5}>
                  Imagens
                </Text>

                <Thumbnails>
                  {images.map((file, index) => {
                    return (
                      <Touchable
                        key={file.id}
                        onPress={() =>
                          navigate('ImagesViewer', {
                            index,
                            images: images.map((image) => (image.opt_info || image.info).url),
                          })
                        }
                      >
                        <Thumbnail source={{ uri: file.thumbnailUrl }} />
                      </Touchable>
                    );
                  })}
                </Thumbnails>
              </>
            )}

            {videos.length > 0 && (
              <>
                <Text h3 bold paddedTop={3} paddedBottom={0.5}>
                  Vídeos
                </Text>

                <Thumbnails>
                  {videos.map((file, index) => {
                    return (
                      <Touchable
                        key={file.id}
                        onPress={() =>
                          navigate('VideosViewer', {
                            index,
                            videos: videos.map((video) => (video.opt_info || video.info).url),
                          })
                        }
                      >
                        <Thumbnail source={{ uri: file.thumbnailUrl }} />
                      </Touchable>
                    );
                  })}
                </Thumbnails>
              </>
            )}

            {others.length > 0 && (
              <>
                <Text h3 bold paddedTop={3} paddedBottom>
                  Arquivos
                </Text>

                {others.map((file) => {
                  return (
                    <Button
                      key={file.id}
                      padded
                      textProps={{ style: { textAlign: 'left' } }}
                      onPress={() => Linking.openURL(file.info.url ?? '')}
                      spacedBottom
                    >
                      <AntDesign name="download" size={18} />
                      &nbsp;&nbsp;
                      {file.name}
                    </Button>
                  );
                })}
              </>
            )}
          </>
        )}

        {task?.task.step === 'approval' && (
          <>
            <Text h3 bold paddedTop={3} paddedBottom={0.5} spacedBottom style={{ flex: 1 }}>
              Aprovação
            </Text>

            <Input
              multiline
              placeholder="Escreva aqui o seu feedback para nossa equipe"
              ref={inputRef}
              onFocus={handleFocus}
              value={feedback}
              onContentSizeChange={(event) => setFeedbackHeight(event.nativeEvent.contentSize.height)}
              onChangeText={(text: string) => setFeedback(text)}
              style={{ height: Math.max(38, feedbackHeight) }}
            />

            <ApprovalActions>
              <Button
                textProps={{ bold: true, h3: true }}
                paddedY={1.5}
                error
                style={{ flex: 1, marginRight: 4 }}
                onPress={handleRejectTask}
                disabled={rejecting || approving}
              >
                Rejeitar&nbsp;&nbsp;
                <FontAwesome name="thumbs-o-down" size={18} />
              </Button>

              <Button
                textProps={{ bold: true, h3: true }}
                paddedY={1.5}
                success
                style={{ flex: 1, marginLeft: 4 }}
                onPress={handleApproveTask}
                disabled={rejecting || approving}
              >
                Aprovar&nbsp;&nbsp;
                <FontAwesome name="thumbs-o-up" size={18} />
              </Button>
            </ApprovalActions>
          </>
        )}

        {!loading && (
          <Button
            textProps={{ h3: true, centered: true }}
            spacedTop={4}
            padded
            onPress={() => goBack()}
            disabled={rejecting || approving}
          >
            Voltar
          </Button>
        )}
      </KeyboardAwareScrollView>
    </Screen>
  );
};

const Thumbnails = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  padding: 8px 0;
`;

const Thumbnail = styled.Image`
  width: 64px;
  height: 64px;
  margin: 0 4px 4px 0;
  background-color: #00000040;
`;

const ApprovalActions = styled.View`
  flex-direction: row;
  padding: 8px 0 0;
`;

export default observer(Task);
